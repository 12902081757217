import { useFormikContext } from 'formik';
import { useHistory } from 'react-router-dom';
import { MenuItem, Tab } from 'semantic-ui-react';

import { LpButtonAsLink } from 'features/common/as_components';
import { LpIcon, graduationCapSolid } from 'features/common/lp_icon';
import { useJiraProjectModalContext } from 'features/jira_project/modal/jira_project_modal_context';
import Section from 'features/jira_project/modal/sections/section';
import TabName from 'features/jira_project/modal/sections/tab_name';
import { JiraProjectModalFormFields, JiraTab, JiraTabKey } from 'features/jira_project/modal/types';
import { useGetJiraProjectModalScreenData } from 'features/jira_project/modal/use_get_jira_project_modal_screen_data';
import { canNavigateToTab, hasErrorsInActiveTab } from 'features/jira_project/modal/utils';
import { frontend } from 'lib/urls';

const jiraAcademyPermalink = frontend.portableAcademyLesson.url({
  portableLinkName: 'integrations_jira_integration',
});

const learnMore = {
  menuItem: (
    <LpButtonAsLink
      type="button"
      openInNewWindow
      className="jira-project-modal__learn-more"
      to={jiraAcademyPermalink}
      icon={<LpIcon icon={graduationCapSolid} />}
      content="Learn More"
      primary={false}
    />
  ),
};

const Content = () => {
  const history = useHistory();
  const { activeTab, tabs, completedTabs, packageId } = useJiraProjectModalContext();
  const { setFieldTouched } = useFormikContext<JiraProjectModalFormFields>();
  useGetJiraProjectModalScreenData();

  const handleClick = async (jiraTab: JiraTabKey) => {
    if (await hasErrorsInActiveTab({ activeTab, setFieldTouched })) {
      return;
    }
    history.push(`?packageId=${packageId}#panelSection=${jiraTab}`);
  };

  const createTab = ({ name, component, key }: JiraTab) => ({
    menuItem: (
      <MenuItem
        disabled={!canNavigateToTab({ name, completedTabs })}
        key={key}
        data-testid="jira-project-modal-section"
        onClick={() => handleClick(key)}
      >
        <TabName name={name} completed={completedTabs[key]} dataTestId={`jira-project-panel-tab-${key}`} />
      </MenuItem>
    ),
    render: () => <Section name={name} TabComponent={component} />,
  });

  const panes = tabs.map(({ component, name, key }: JiraTab) =>
    createTab({
      name,
      component,
      key,
    })
  );

  const index = tabs.findIndex((tab) => tab.key === activeTab);
  const activeTabOrFirstIndex = Math.max(0, index);

  return (
    <Tab
      data-testid="jira-project-modal-sections"
      activeIndex={activeTabOrFirstIndex}
      className="jira-project-modal__tabs"
      menu={{ fluid: true, vertical: true }}
      panes={[...panes, learnMore]}
    />
  );
};

export default Content;
