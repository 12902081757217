import { useState } from 'react';
import { Button } from 'semantic-ui-react';

import LpModal from 'features/common/modals/lp_modal';
import { JiraProjectTable } from 'features/jira_project/modal/common/jira_project_table';
import { useJiraProjectModalContext } from 'features/jira_project/modal/jira_project_modal_context';
import { AdvancedFilters } from 'features/jira_project/modal/types';

import { AdvancedFilterRow } from './advanced_filter_row';

import './advanced_filters_modal.scss';

interface AdvancedFiltersModalProps {
  handleSaveModal: (advancedFilters: AdvancedFilters) => void;
  closeModal: () => void;
  existingFilters?: AdvancedFilters;
}

export const AdvancedFiltersModal = ({ handleSaveModal, closeModal, existingFilters }: AdvancedFiltersModalProps) => {
  const [advancedFilters, setAdvancedFilters] = useState<AdvancedFilters>(existingFilters || {});
  const { jiraAdvancedFilters } = useJiraProjectModalContext();

  const handleResetAll = () => {
    setAdvancedFilters({});
  };

  const handleCloseModal = () => {
    handleResetAll();
    closeModal();
  };

  return (
    <LpModal
      className="advanced-filters-modal"
      size="tiny"
      header="Advanced Filters"
      actions={
        <div className="advanced-filters-modal-actions">
          <Button onClick={handleResetAll} type="button">
            Reset All
          </Button>
          <span className="advanced-filters-modal-actions-right">
            <Button onClick={handleCloseModal} type="button">
              Close
            </Button>
            <Button onClick={() => handleSaveModal(advancedFilters)} type="button" primary>
              Save
            </Button>
          </span>
        </div>
      }
      content={
        <div className="advanced-filters-modal-content">
          <JiraProjectTable>
            {jiraAdvancedFilters.map((filter) => (
              <AdvancedFilterRow
                advancedFilters={advancedFilters}
                setAdvancedFilters={setAdvancedFilters}
                key={filter.displayName}
                filter={filter}
              />
            ))}
          </JiraProjectTable>
        </div>
      }
    />
  );
};
