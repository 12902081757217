import classNames from 'classnames';
import { Image } from 'semantic-ui-react';

import { LIQUIDPLANNER_URL } from 'lib/constants';
import NewWindowLink from 'lib/display_helpers/url_links';
import pmLogo from 'static/img/portfolio-manager-logo.svg';

export const LpLogo = ({ className }: { className?: string }) => (
  <NewWindowLink to={LIQUIDPLANNER_URL}>
    <Image src={pmLogo} className={classNames('lp-logo', 'clickable', className)} />
  </NewWindowLink>
);
