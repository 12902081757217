import { useFormikContext } from 'formik';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { JiraSuggestionsDao } from 'daos/jira_suggestions';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { JiraProjectAutocompleteDropdown } from 'features/jira_project/modal/common/jira_project_autocomplete_dropdown';
import { useJiraProjectModalContext } from 'features/jira_project/modal/jira_project_modal_context';
import {
  JiraAutoCompleteResult,
  JiraProjectModalFormFields,
  JiraProjectModalFormValues,
} from 'features/jira_project/modal/types';

interface JiraAutocompleteDropdownProps {
  index: number;
  parent?: string;
  placeholder?: string;
  selectedValues:
    | JiraProjectModalFormValues[JiraProjectModalFormFields.ParentIssueIdsOrKeys]
    | ReadonlyArray<JiraAutoCompleteResult>;
  setFieldValue: ({ newValue, index }: { newValue?: JiraAutoCompleteResult; index?: number }) => void;
}

export const JiraAutocompleteDropdown = ({
  index,
  parent = 'parent',
  placeholder = 'Search for parent issues...',
  selectedValues,
  setFieldValue,
}: JiraAutocompleteDropdownProps) => {
  const organizationId = useSelector((state) => getCurrentOrganizationId(state));
  const workspaceId = useSelector((state) => getCurrentWorkspaceId(state));
  const { integrationId: externalIntegrationId } = useJiraProjectModalContext();
  const { values } = useFormikContext<JiraProjectModalFormValues>();

  const showRemoveButton = index !== selectedValues.length;

  const jiraSuggestionsFetchFn = useCallback(
    (value: string) =>
      JiraSuggestionsDao.fetchJiraSuggestions(
        { organizationId, workspaceId },
        {
          fieldName: parent,
          fieldValue: value ?? '',
          cloudId: values[JiraProjectModalFormFields.CloudId],
          oauthCredentialsId: values[JiraProjectModalFormFields.OauthCredentialsId],
          externalIntegrationId,
        }
      ),
    [externalIntegrationId, organizationId, values, workspaceId, parent]
  );

  return (
    <JiraProjectAutocompleteDropdown
      placeholder={placeholder}
      index={index}
      showRemoveButton={showRemoveButton}
      fetchSuggestionsFn={jiraSuggestionsFetchFn}
      selectedValues={selectedValues}
      setFieldValue={setFieldValue}
    />
  );
};
