import { defineModel } from 'daos/shared';
import { backend } from 'daos/urls';
import { request } from 'lib/api';
import { HttpMethod } from 'lib/api/types';

const { resource } = defineModel({
  apiType: 'jiraAdvancedFiltering',
  type: 'JIRA_ADVANCED_FILTERS',
});

const { JIRA_ADVANCED_FILTERS } = resource;

interface JiraAdvancedFiltersParams {
  organizationId: number;
  workspaceId: number;
}

interface JiraAdvancedFiltersQuery {
  cloudId?: string;
  oauthCredentialsId?: number;
}

interface JiraAdvancedFiltersData {
  projectIds: Array<string>;
}

const fetchJiraAdvancedFilters = (
  params: JiraAdvancedFiltersParams,
  query: JiraAdvancedFiltersQuery,
  data: JiraAdvancedFiltersData
) => {
  return request(backend.jiraAdvancedFiltersFields.url(params, { query }), JIRA_ADVANCED_FILTERS, {
    method: HttpMethod.POST,
    body: JSON.stringify(data),
  });
};
export const JiraAdvancedFiltersDao = {
  fetchJiraAdvancedFilters,
} as const;
