import { ComponentType } from 'react';

import { CustomFieldType } from 'daos/enums';
import {
  EstimationType,
  StoryPointSchemeActions,
  TaskGroupHierarchy,
  TaskPushSetting,
} from 'daos/external_integration_enums';
import { LpSvgs } from 'features/common/lp_svg';

export interface JiraProject {
  readonly name: string;
  readonly id: string;
}

export interface JiraAdvancedFilter {
  displayName: string;
  cfid: string | null;
  searchable: boolean;
  types: ReadonlyArray<string>;
  value: string;
}

export interface JiraAdvancedFiltering {
  visibleFieldNames: ReadonlyArray<JiraAdvancedFilter>;
}

export enum JiraProjectModalFormFields {
  AdvancedFilters = 'advancedFilters',
  CaptureSprints = 'captureSprints',
  CloudId = 'cloudId',
  CostCodeId = 'costCodeId',
  EstimationType = 'estimationType',
  ExistingSchemeId = 'existingSchemeId',
  FieldMappings = 'fieldMappings',
  HighEstimate = 'highEstimate',
  IssueCreatedDate = 'issueCreatedDate',
  IssueStatusIds = 'issueStatusIds',
  IssueTypeIds = 'issueTypeIds',
  JiraProjectId = 'jiraProjectId',
  LowEstimate = 'lowEstimate',
  OauthCredentialsId = 'oauthCredentialsId',
  ParentIssueIdsOrKeys = 'parentIssueIdsOrKeys',
  RankIssues = 'rankIssues',
  SelectedExistingSchemeToCopy = 'selectedExistingSchemeToCopy',
  StoryPointSchemeActions = 'storyPointSchemeActions',
  StoryPointSchemeName = 'storyPointSchemeName',
  StoryPoints = 'storyPoints',
  SyncJiraWorklog = 'syncJiraWorklog',
  SyncTimesheetWorklog = 'syncTimesheetWorklog',
  TaskGroupHierarchy = 'taskGroupHierarchy',
  TaskPushSetting = 'taskPushSetting',
}

enum JiraProjectModalFormFieldMappingItem {
  LpCustomFieldId = 'lpCustomFieldId',
  LpSystemField = 'lpSystemField',
  FieldType = 'fieldType',
  SourceFieldId = 'sourceFieldId',
  IsPull = 'isPull',
  IsPush = 'isPush',
}
interface JiraStoryPointsMapping {
  value: number;
  lowEffort: number;
  highEffort: number;
}

enum JiraProjectModalStoryPointsMappingItem {
  Value = 'value',
  LowEffort = 'lowEffort',
  HighEffort = 'highEffort',
}

export type AllowedCustomField =
  | CustomFieldType.DATE
  | CustomFieldType.NUMERIC
  | CustomFieldType.TEXT
  | CustomFieldType.PICKLIST
  | CustomFieldType.MULTI_PICKLIST
  | CustomFieldType.LINK
  | CustomFieldType.USER
  | CustomFieldType.MULTI_USER;

export interface JiraProjectModalFormFieldMapping {
  [JiraProjectModalFormFieldMappingItem.FieldType]: AllowedCustomField;
  [JiraProjectModalFormFieldMappingItem.SourceFieldId]: string;
  [JiraProjectModalFormFieldMappingItem.LpCustomFieldId]: number | null;
  [JiraProjectModalFormFieldMappingItem.LpSystemField]: string | null;
  [JiraProjectModalFormFieldMappingItem.IsPull]: boolean;
  [JiraProjectModalFormFieldMappingItem.IsPush]: boolean;
}

interface JiraProjectModalStoryPointsMapping {
  [JiraProjectModalStoryPointsMappingItem.Value]?: string;
  [JiraProjectModalStoryPointsMappingItem.LowEffort]?: string;
  [JiraProjectModalStoryPointsMappingItem.HighEffort]?: string;
}

export interface AdvancedFilters {
  [parent: string]: ReadonlyArray<JiraAutoCompleteResult>;
}
export interface JiraProjectModalFormValues {
  [JiraProjectModalFormFields.AdvancedFilters]: AdvancedFilters;
  [JiraProjectModalFormFields.CaptureSprints]: boolean;
  [JiraProjectModalFormFields.CloudId]: string;
  [JiraProjectModalFormFields.CostCodeId]: number | null;
  [JiraProjectModalFormFields.EstimationType]: EstimationType;
  [JiraProjectModalFormFields.ExistingSchemeId]: number | null;
  [JiraProjectModalFormFields.FieldMappings]: Array<JiraProjectModalFormFieldMapping>;
  [JiraProjectModalFormFields.HighEstimate]: number;
  [JiraProjectModalFormFields.IssueCreatedDate]: string;
  [JiraProjectModalFormFields.IssueStatusIds]: Array<string>;
  [JiraProjectModalFormFields.IssueTypeIds]: Array<string>;
  [JiraProjectModalFormFields.JiraProjectId]: string;
  [JiraProjectModalFormFields.LowEstimate]: number;
  [JiraProjectModalFormFields.OauthCredentialsId]: number;
  [JiraProjectModalFormFields.ParentIssueIdsOrKeys]: Array<string>;
  [JiraProjectModalFormFields.RankIssues]: boolean;
  [JiraProjectModalFormFields.SelectedExistingSchemeToCopy]: number | null;
  [JiraProjectModalFormFields.StoryPointSchemeActions]: StoryPointSchemeActions;
  [JiraProjectModalFormFields.StoryPointSchemeName]: string;
  [JiraProjectModalFormFields.StoryPoints]: Array<JiraProjectModalStoryPointsMapping> | null;
  [JiraProjectModalFormFields.SyncJiraWorklog]: boolean;
  [JiraProjectModalFormFields.SyncTimesheetWorklog]: boolean;
  [JiraProjectModalFormFields.TaskGroupHierarchy]: TaskGroupHierarchy;
  [JiraProjectModalFormFields.TaskPushSetting]: TaskPushSetting;
}
export interface JiraTab {
  component: ComponentType;
  name: TabNames;
  key: JiraTabKey;
}
export interface JiraProjectIssueCount {
  count: number;
}

export enum FieldMappingRowType {
  JiraToLp = 'jiraToLp',
  LpToJira = 'lpToJira',
}

export enum JiraTabKey {
  Welcome = 'welcome',
  JiraInstance = 'jira-instance-and-credentials',
  ProjectAndIssuesFilter = 'project-and-issues-filter',
  SyncSettings = 'sync-settings',
  FieldMapping = 'field-mapping',
  RemainingEstimates = 'remaining-estimates',
  Launch = 'launch',
}

export enum TabNames {
  Welcome = 'Welcome',
  JiraInstanceAndCredentials = 'Instance & Credentials',
  ProjectAndIssuesFilter = 'Project & Issues Filter',
  SyncSettings = 'Sync Settings',
  FieldMapping = 'Field Mapping',
  RemainingEstimates = 'Remaining Estimates',
  Launch = 'Launch',
}

export const jiraFieldDateMapping = {
  id: 'duedate',
  name: 'Due Date',
  type: CustomFieldType.DATE,
};

export interface FieldMappingValue {
  id: string;
  name: string;
  type: string;
  custom?: string;
  isMulti?: boolean;
  isEditableOn?: ReadonlyArray<string>;
  isMandatoryOnCreationFor?: ReadonlyArray<string>;
}

export interface IssueTypeValue {
  id: string;
  name: string;
}

export interface JiraFieldMappings {
  issueTypes: ReadonlyArray<IssueTypeValue>;
  fields: ReadonlyArray<FieldMappingValue>;
}

export interface JiraAutoCompleteResult {
  displayName: string;
  value: string;
}

export enum LpSystemName {
  TargetFinish = 'Target Finish',
  TargetStart = 'Target Start',
  ExpectedStart = 'Expected Start',
  ExpectedFinish = 'Expected Finish',
  LatestFinish = 'Latest Finish',
  LpId = 'LiquidPlanner ID',
  ItemUrl = 'Item Link',
  WorkLimit = 'Work Limit',
  BenchmarkEstimate = 'Benchmark Estimate',
}

export enum LpSystemId {
  TargetFinish = 'TARGET_FINISH',
  TargetStart = 'TARGET_START',
  ExpectedStart = 'EXPECTED_START',
  ExpectedFinish = 'EXPECTED_FINISH',
  LatestFinish = 'LATEST_FINISH',
  LpId = 'ID',
  ItemUrl = 'ITEM_URL',
  WorkLimit = 'WORK_LIMIT',
  BenchmarkEstimate = 'BENCHMARK_ESTIMATE',
}

export enum CalculatedLpSystemId {
  LpId = 'ID',
  ExpectedStart = 'EXPECTED_START',
  ExpectedFinish = 'EXPECTED_FINISH',
  LatestFinish = 'LATEST_FINISH',
  ItemUrl = 'ITEM_URL',
}

export const jiraDueDateTargetFinishInitialValue: JiraProjectModalFormFieldMapping = {
  sourceFieldId: 'duedate',
  lpCustomFieldId: null,
  lpSystemField: LpSystemId.TargetFinish,
  fieldType: CustomFieldType.DATE,
  isPull: true,
  isPush: false,
};

export interface JiraProjectModalPicklistData {
  id: string | number;
  name: string;
  disabled?: boolean;
}

export const lpNameSystemMapping: Array<FieldMappingValue> = [
  {
    id: LpSystemId.TargetFinish,
    name: LpSystemName.TargetFinish,
    type: CustomFieldType.DATE,
  },
  {
    id: LpSystemId.TargetStart,
    name: LpSystemName.TargetStart,
    type: CustomFieldType.DATE,
  },
  {
    id: LpSystemId.ExpectedStart,
    name: LpSystemName.ExpectedStart,
    type: CustomFieldType.DATE,
  },
  {
    id: LpSystemId.ExpectedFinish,
    name: LpSystemName.ExpectedFinish,
    type: CustomFieldType.DATE,
  },
  {
    id: LpSystemId.LatestFinish,
    name: LpSystemName.LatestFinish,
    type: CustomFieldType.DATE,
  },
  {
    id: LpSystemId.LpId,
    name: LpSystemName.LpId,
    type: CustomFieldType.NUMERIC,
  },
  {
    id: LpSystemId.ItemUrl,
    name: LpSystemName.ItemUrl,
    type: CustomFieldType.LINK,
  },
  {
    id: LpSystemId.WorkLimit,
    name: LpSystemName.WorkLimit,
    type: CustomFieldType.NUMERIC,
  },
  {
    id: LpSystemId.BenchmarkEstimate,
    name: LpSystemName.BenchmarkEstimate,
    type: CustomFieldType.NUMERIC,
  },
];

export const expectedFinishItem = Array.from(lpNameSystemMapping).find((item) => item.id === LpSystemId.ExpectedFinish);

export enum SyncErrorMessage {
  JiraProjectNotFound = 'TBD',
  InvalidResource = 'Authentication failed: You do not have access to the connected JIra Instance. Please check your permissions and try again.',
  DisconnectedUser = 'Authentication error: the authenticated user account has been disconnected. Click on the "Replace Authentication" button to reconnect with a different account.',
}

export enum TimesheetAuthStatus {
  Success = 'SUCCESS',
  Failure = 'FAILURE',
  Error = 'ERROR',
}

export interface TimesheetAuthResponse {
  result: TimesheetAuthStatus;
}

export enum WorklogOption {
  DO_NOT_PULL = 'Do not pull any Worklogs',
  PULL_FROM_JIRA = 'Pull from Jira',
  PULL_FROM_TEMPO_TIMESHEETS = 'Pull from Tempo Timesheets',
}

export enum SubFolderOrganizationName {
  FLAT_LIST = 'Flat List',
  EPIC_BASED_SUBFOLDERS = 'Epic-Based Sub-folders',
  HIERARCHICAL_SUBFOLDERS = 'Hierarchical Sub-folders',
}

export interface SubFolderOrganizationValues {
  id: TaskGroupHierarchy;
  name: SubFolderOrganizationName;
  description: string;
  subtitle: string;
  image: LpSvgs;
}

export interface TaskPushSettingValue {
  id: TaskPushSetting;
  name: string;
  description: string;
}

export enum JiraCustomFieldType {
  TextField = 'com.atlassian.jira.plugin.system.customfieldtypes:textfield',
  Url = 'com.atlassian.jira.plugin.system.customfieldtypes:url',
  Sprint = 'com.pyxis.greenhopper.jira:gh-sprint',
  People = 'com.atlassian.jira.plugin.system.customfieldtypes:people',
  UserPicker = 'com.atlassian.jira.plugin.system.customfieldtypes:userpicker',
  MultiUserPicker = 'com.atlassian.jira.plugin.system.customfieldtypes:multiuserpicker',
}

export enum JiraCustomFieldId {
  OriginalEstimate = 'timeoriginalestimate',
}

export enum EstimationTypeDisplay {
  TimeRemaining = 'Time Remaining',
  StoryPoints = 'Story Points',
}

export interface EstimationTypeValues {
  text: EstimationTypeDisplay;
  value: EstimationType;
}

export enum SchemeDisplayNames {
  CreateNewScheme = 'Create New Scheme',
  CopyExistingScheme = 'Copy Existing Scheme',
}

export const storyPointsSchemeInitialValues: Array<JiraStoryPointsMapping> = [
  { value: 1, lowEffort: 6, highEffort: 10 },
  { value: 2, lowEffort: 12, highEffort: 20 },
  { value: 3, lowEffort: 18, highEffort: 30 },
  { value: 5, lowEffort: 30, highEffort: 50 },
  { value: 8, lowEffort: 48, highEffort: 80 },
  { value: 13, lowEffort: 78, highEffort: 130 },
  { value: 21, lowEffort: 126, highEffort: 210 },
];

export enum JiraTable {
  JiraToLp = 'JiraToLp',
  LpToJira = 'LpToJira',
  StoryPointsScheme = 'StoryPointsScheme',
}
