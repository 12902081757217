import { LocationFilterAssigneeSettings } from 'daos/types';

export interface IterationFilter {
  status: IterationStatusFilter;
  assignee?: LocationFilterAssigneeSettings;
}
export enum IterationStatusFilter {
  All = 'all',
  Active = 'active',
  Done = 'done',
}
