import { Popup } from 'semantic-ui-react';

export enum BulkOptionPopupContent {
  Assignment = 'Add Assignment',
  Cancel = 'Clear Selection',
  ClearTasks = 'Clear Tasks',
  Delete = 'Delete',
  DeleteIteration = 'Delete Iteration',
  Edit = 'Edit',
  EditDisabled = 'Select fewer items (up to 50 rows) for bulk editing.',
  Move = 'Move',
  PinItem = 'Pin to My Work',
  RemovePin = 'Remove pin',
  SelectTasks = 'Select Tasks',
  ScheduleRush = 'Schedule Rush',
  SchedulePriority = 'Schedule Priority',
  SwapAssignments = 'Swap Assignments',
  SwapAssignmentsDisabled = 'Select fewer items (up to 50 rows) for bulk Swap Assignments.',
  TaskStatus = 'Task Status',
}

interface BulkOptionPopUpProps {
  children: JSX.Element;
  content: BulkOptionPopupContent;
}

export const BulkOptionPopup = ({ children, content }: BulkOptionPopUpProps) => (
  <Popup trigger={children} content={content} basic position={'bottom center'} size="tiny" />
);
