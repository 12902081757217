import { AdvancedFilters, JiraAutoCompleteResult } from 'features/jira_project/modal/types';

interface UseAdvancedFiltersProps {
  parentId: string;
  advancedFilters: AdvancedFilters;
  setAdvancedFilters: (filters: AdvancedFilters) => void;
}

export const useAdvancedFilters = ({ parentId, advancedFilters, setAdvancedFilters }: UseAdvancedFiltersProps) => {
  const selectedValues = advancedFilters[parentId] ?? [];

  const setFieldValueForParentId = ({ newValue, index }: { newValue?: JiraAutoCompleteResult; index?: number }) => {
    if (index === undefined) {
      return setAdvancedFilters({ ...advancedFilters, [parentId]: [] });
    }
    const updatedValues = [...selectedValues];

    if (newValue === undefined) {
      updatedValues.splice(index, 1);
    } else {
      updatedValues[index] = newValue;
    }

    setAdvancedFilters({ ...advancedFilters, [parentId]: updatedValues });
  };
  return { selectedValues, setFieldValue: setFieldValueForParentId };
};
