import classNames from 'classnames';
import { noop } from 'lodash';
import { FunctionComponent, HTMLAttributes } from 'react';

import LpPopUp from 'features/common/lp_popup';
import {
  EpicBasedSubFolderSvg,
  FlatListSvg,
  HierarchicalSubFoldersSvg,
  JiraBlueGradientSvg,
  JiraSoftwareBlueSvg,
  JiraSvg,
  JiraSvgWhite,
  LpWavySvg,
  PortfolioSvg,
  TimesheetLogoSvg,
  WhitePortfolioSvg,
  PortfolioManagerWavySvg,
  TempoLogoSvg,
  TempoLogoNoTextSvg,
  NovaPlaceholderLogoSvg,
} from 'features/common/lp_svg/svg/';
import { LpSvgs } from 'features/common/lp_svg/types';

import './lp_svg.scss';

interface Props extends HTMLAttributes<HTMLSpanElement> {
  color?: string;
  size?: number;
  onClick?: (e: React.MouseEvent<HTMLSpanElement>) => void;
  testId?: string;
  icon: LpSvgs;
  className?: string;
  height?: number;
  width?: number;
  hoverText?: string;
}

const getSvg = (icon: LpSvgs) => {
  switch (icon) {
    case LpSvgs.LP_WAVY:
      return LpWavySvg;
    case LpSvgs.JIRA:
      return JiraSvg;
    case LpSvgs.JIRA_WHITE:
      return JiraSvgWhite;
    case LpSvgs.JIRA_SOFTWARE:
      return JiraSoftwareBlueSvg;
    case LpSvgs.PORTFOLIO_JIRA:
      return WhitePortfolioSvg;
    case LpSvgs.JIRA_BLUE_GRADIENT:
      return JiraBlueGradientSvg;
    case LpSvgs.PORTFOLIO:
      return PortfolioSvg;
    case LpSvgs.TIMESHEET_LOGO:
      return TimesheetLogoSvg;
    case LpSvgs.FLAT_LIST:
      return FlatListSvg;
    case LpSvgs.EPIC_BASED_SUBFOLDER:
      return EpicBasedSubFolderSvg;
    case LpSvgs.HIERARCHICAL_SUBFOLDERS:
      return HierarchicalSubFoldersSvg;
    case LpSvgs.PORTFOLIO_MANAGER_WAVY:
      return PortfolioManagerWavySvg;
    case LpSvgs.TEMPO_LOGO:
      return TempoLogoSvg;
    case LpSvgs.TEMPO_LOGO_NO_TEXT:
      return TempoLogoNoTextSvg;
    case LpSvgs.NOVA_PLACEHOLDER_LOGO:
      return NovaPlaceholderLogoSvg;
    default:
      return LpWavySvg;
  }
};

const LpSvg: FunctionComponent<Props> = ({
  icon,
  color = 'currentColor',
  size = 20,
  onClick = () => noop,
  testId,
  className,
  height,
  width,
  hoverText,
  ...props
}: Props): JSX.Element => {
  const SvgComponent = getSvg(icon);

  const svgContent = (
    <span
      className={classNames('lp-svg', className)}
      style={{ height: height ?? size, width: width ?? size, color }}
      onClick={onClick}
      data-testid={testId || `${SvgComponent.name}Icon`}
      {...props}
    >
      <SvgComponent color={color} />
    </span>
  );

  return hoverText ? (
    <LpPopUp positionStrategy="fixed" placement="top" content={<div>{hoverText}</div>} trigger={svgContent} />
  ) : (
    svgContent
  );
};

export { LpSvgs, LpSvg };
