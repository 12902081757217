export const TempoLogoNoTextSvg = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      d="M18.3718 0.216797L8.26873 10.3199L4.89587 6.94701L1.52393 10.319L4.89496 13.69C6.75833 15.5534 9.77913 15.5534 11.6425 13.69L21.7447 3.58783L18.3718 0.216797Z"
      fill="#F54D42"
    />
    <path
      d="M2.48071 22.2725L16.2818 25.9705L15.0472 30.5779L19.6534 31.8121L20.8873 27.2072C21.5693 24.6618 20.0589 22.0457 17.5135 21.3637L3.71369 17.666L2.48071 22.2725Z"
      fill="#9664FE"
    />
    <path
      d="M29.5381 25.0422L25.8401 11.2411L30.4475 10.0066L29.2133 5.40039L24.6084 6.63427C22.0629 7.31632 20.5525 9.9324 21.2346 12.4778L24.9322 26.2776L29.5381 25.0422Z"
      fill="#FFCC24"
    />
  </svg>
);
