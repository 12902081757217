// Keep in sync with com.liquidplanner.backend.util
// Use camel case
export enum FeatureFlag {
  benchmarkEstimate = 'benchmarkEstimate',
  groupCapacity = 'groupCapacity',
  jiraExpandedFiltering = 'jiraExpandedFiltering',
  jiraIntegration = 'jiraIntegration',
  jiraIssueCreation = 'jiraIssueCreation',
  jiraMultiProjectPull = 'jiraMultiProjectPull',
  jiraTempoWowUseCases = 'jiraTempoWowUseCases',
  jiraSprintToIterations = 'jiraSprintToIterations',
  jiraStoryPoints = 'jiraStoryPoints',
  iterationBucket = 'iterationBucket',
  disablePendo = 'disablePendo',
  storyPoints = 'storyPoints',
  widgetQuickFilter = 'widgetQuickFilter',
  showAssignmentGridTypeInPortfolioGrid = 'showAssignmentGridTypeInPortfolioGrid',
  createBranchNameInRelightOnly = 'createBranchNameInRelightOnly',
  jiraParentFilter = 'jiraParentFilter',
  projectNova = 'projectNova',
  teamBasedPlanning = 'teamBasedPlanning',
}
