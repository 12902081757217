export const NovaPlaceholderLogoSvg = () => (
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none">
    <path
      d="M2 8C2 4.68629 4.68629 2 8 2H36C39.3137 2 42 4.68629 42 8V36C42 39.3137 39.3137 42 36 42H8C4.68629 42 2 39.3137 2 36V8Z"
      fill="#F0F0F0"
    />
    <mask
      id="mask0_513_24249"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="10"
      y="10"
      width="24"
      height="24"
    >
      <rect x="10" y="10" width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_513_24249)">
      <path
        d="M15 26.175C15.1667 26.125 15.3292 26.0833 15.4875 26.05C15.6458 26.0167 15.8167 26 16 26H17V14H16C15.7167 14 15.4792 14.0958 15.2875 14.2875C15.0958 14.4792 15 14.7167 15 15V26.175ZM16 32C15.1667 32 14.4583 31.7083 13.875 31.125C13.2917 30.5417 13 29.8333 13 29V15C13 14.1667 13.2917 13.4583 13.875 12.875C14.4583 12.2917 15.1667 12 16 12H23V14H19V26H25V23H27V28H16C15.7167 28 15.4792 28.0958 15.2875 28.2875C15.0958 28.4792 15 28.7167 15 29C15 29.2833 15.0958 29.5208 15.2875 29.7125C15.4792 29.9042 15.7167 30 16 30H29V22H31V32H16ZM27.5 22C27.5 20.4667 28.0333 19.1667 29.1 18.1C30.1667 17.0333 31.4667 16.5 33 16.5C31.4667 16.5 30.1667 15.9667 29.1 14.9C28.0333 13.8333 27.5 12.5333 27.5 11C27.5 12.5333 26.9667 13.8333 25.9 14.9C24.8333 15.9667 23.5333 16.5 22 16.5C23.5333 16.5 24.8333 17.0333 25.9 18.1C26.9667 19.1667 27.5 20.4667 27.5 22Z"
        fill="black"
      />
    </g>
  </svg>
);
