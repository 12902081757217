import { jira, LpIcon } from 'features/common/lp_icon';
import { LpSvg, LpSvgs } from 'features/common/lp_svg';
import { ProductName } from 'lib/use_product_name';

export const Legend = () => (
  <div className="jira-project-modal__content-legend">
    <div className="jira-project-modal__content-legend-item">
      <LpIcon icon={jira} size="lg" />
      Jira Field
    </div>
    <div className="jira-project-modal__content-legend-item">
      <LpSvg icon={LpSvgs.PORTFOLIO_MANAGER_WAVY} size={17} />
      <ProductName /> Field
    </div>
    <div className="jira-project-modal__content-legend-item">
      <LpSvg icon={LpSvgs.JIRA_BLUE_GRADIENT} size={17} />
      Jira Field Required For Syncing New Tasks
    </div>
  </div>
);
