import { Table } from 'semantic-ui-react';

import { StandardColumns } from 'containers/shared/custom_column/enum';
import { WidgetWithData } from 'daos/model_types';
import { WidgetConfigColumn } from 'daos/widget_column';
import { getFormattedDisplay } from 'features/common/data_grid/safe_widgets/formatter_helpers';
import {
  ItemNameLink,
  getOnClickViewAwareItemLinkToURL,
} from 'features/dashboards_v2/widget/widgets/list/table/item_name_cell';
import { PeopleListWidgetUsernameLink } from 'features/dashboards_v2/widget/widgets/list/table/user_name_cell';
import { ColumnDataMap, GridSummaryRow, ItemRow, PeopleRow } from 'features/dashboards_v2/widget/widgets/reports/types';

const ColumnCell = ({
  columnDataMap,
  columnDefinition,
  isSummaryRow,
}: {
  columnDataMap: ColumnDataMap;
  columnDefinition: WidgetConfigColumn;
  isSummaryRow?: boolean;
}) => {
  const { column, customFieldId } = columnDefinition;
  const isScheduleColumn = column === StandardColumns.ScheduleBar;
  const columnKey = customFieldId ? String(customFieldId) : column;
  const display = getFormattedDisplay(columnKey, columnDataMap, isSummaryRow);

  return (
    <Table.Cell
      className={isScheduleColumn ? 'custom-column__schedule-bar' : ''}
      collapsing
      textAlign={isScheduleColumn ? 'left' : 'right'}
    >
      {display}
    </Table.Cell>
  );
};

export const ItemListTableRow = ({
  itemRow,
  columnDefinitions,
  widget,
}: {
  itemRow: ItemRow;
  columnDefinitions: ReadonlyArray<WidgetConfigColumn>;
  widget: WidgetWithData;
}) => {
  const toURl = getOnClickViewAwareItemLinkToURL(widget, itemRow.itemLink);

  return (
    <Table.Row>
      <Table.Cell>
        <ItemNameLink widget={widget} itemRow={itemRow} customToURL={toURl} />
      </Table.Cell>

      {columnDefinitions.map((columnDefinition) => (
        <ColumnCell
          key={`${columnDefinition.column}_${columnDefinition.customFieldId}`}
          columnDataMap={itemRow.columnDataMap}
          columnDefinition={columnDefinition}
        />
      ))}
    </Table.Row>
  );
};

export const SummaryTableRow = ({
  summaryData,
  columnDefinitions,
}: {
  summaryData: GridSummaryRow;
  columnDefinitions: ReadonlyArray<WidgetConfigColumn>;
}) => {
  return (
    <Table.Row>
      <Table.Cell>
        <strong>{`Total includes all ${summaryData.numberOfRows} rows`}</strong>
      </Table.Cell>

      {columnDefinitions.map((columnDefinition) => (
        <ColumnCell
          key={`${columnDefinition.column}_${columnDefinition.customFieldId}`}
          columnDataMap={summaryData.columnDataMap}
          columnDefinition={columnDefinition}
          isSummaryRow
        />
      ))}
    </Table.Row>
  );
};

export const PeopleListTableRow = ({
  peopleRow,
  columnDefinitions,
  widget,
}: {
  peopleRow: PeopleRow;
  columnDefinitions: ReadonlyArray<WidgetConfigColumn>;
  widget: WidgetWithData;
}) => {
  return (
    <Table.Row>
      <Table.Cell>
        <PeopleListWidgetUsernameLink userData={peopleRow.user} widget={widget} />
      </Table.Cell>

      {columnDefinitions.map((columnDefinition) => (
        <ColumnCell
          key={`${columnDefinition.column}_${columnDefinition.customFieldId}`}
          columnDataMap={peopleRow.columnDataMap}
          columnDefinition={columnDefinition}
        />
      ))}
    </Table.Row>
  );
};
