import { useSelector } from 'react-redux';

import LpLink from 'containers/shared/lp_link';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { LpIcon, arrowLeftRegular } from 'features/common/lp_icon';
import { LpSvg, LpSvgs } from 'features/common/lp_svg';
import { frontend } from 'lib/urls';
import { lpGray500 } from 'style/variables';

import 'features/integrations/jira/jira_integration_connections/jira_connected_projects.scss';

export const JiraIntegrationsEmpty = () => {
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  const params = { organizationId, workspaceId };

  return (
    <div className="jira-configuration-empty">
      <div className="jira-configuration-empty-icons">
        <LpSvg icon={LpSvgs.PORTFOLIO_MANAGER_WAVY} size={90} />
        <LpIcon icon={arrowLeftRegular} color={lpGray500} size="5x" />
        <LpSvg icon={LpSvgs.JIRA} size={90} />
      </div>
      <div className="jira-configuration-empty-text">
        <span className="jira-configuration-empty-text-header">There are no Jira Projects in the workspace</span>
        <span className="jira-configuration-empty-text-message">
          Go to <LpLink to={frontend.scheduledCollectionPortfolio.url(params)}>Projects / SCHEDULED</LpLink> to add a
          Jira Project
        </span>
      </div>
    </div>
  );
};
