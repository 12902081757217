import classNames from 'classnames';
import { ReactNode, SyntheticEvent } from 'react';
import { Label, SemanticCOLORS } from 'semantic-ui-react';

import { ScheduleDirective, ScheduleDirectiveToScheduleDirectiveDisplayText } from 'daos/enums';
import LpPopUp from 'features/common/lp_popup';
import 'containers/shared/notice_icons/icons.scss';

// --------------------------------------------------------------------------
// COLOR SETUP
// --------------------------------------------------------------------------
const colorMap: Map<ScheduleDirective, SemanticCOLORS> = new Map<ScheduleDirective, SemanticCOLORS>();

colorMap.set(ScheduleDirective.ASAP_IN_WORKSPACE, 'red');
colorMap.set(ScheduleDirective.ASAP_IN_PROJECT, 'blue');
colorMap.set(ScheduleDirective.ASAP_IN_PACKAGE, 'orange');
colorMap.set(ScheduleDirective.NORMAL, 'grey');

export const getASAPLabelColor = (scheduleDirective: ScheduleDirective): SemanticCOLORS =>
  colorMap.get(scheduleDirective) ?? 'grey';

interface AsapLabelProps {
  className?: string;
  scheduleDirective: ScheduleDirective;
  onSchedulingClick?: (event: SyntheticEvent) => void;
}

export const ASAPLabelRenderer = ({
  className,
  scheduleDirective,
  onClick,
  content = 'ASAP',
}: {
  className?: string;
  scheduleDirective: ScheduleDirective;
  onClick?: (event: SyntheticEvent) => void;
  content?: ReactNode;
}) => (
  <Label
    content={content}
    className={classNames(className, `asap-${getASAPLabelColor(scheduleDirective)}`)}
    onClick={onClick}
  />
);

export const ASAPLabel = ({ className, scheduleDirective, onSchedulingClick }: AsapLabelProps) => (
  <ASAPLabelRenderer
    className={classNames(className, 'icon notice asap', !!onSchedulingClick && 'asap-clickable')}
    onClick={onSchedulingClick}
    scheduleDirective={scheduleDirective}
    content={
      <LpPopUp
        positionStrategy="fixed"
        placement="top"
        trigger={<span>ASAP</span>}
        content={<span>{ScheduleDirectiveToScheduleDirectiveDisplayText[scheduleDirective]}</span>}
      />
    }
  />
);

export const ASAPLabelWithDisplayText = ({
  scheduleDirective,
  displayText,
  className,
}: {
  scheduleDirective: ScheduleDirective;
  displayText: string;
  className?: string;
}) => {
  return (
    <span className={className}>
      <Label color={getASAPLabelColor(scheduleDirective)} horizontal>
        ASAP
      </Label>
      {displayText}
    </span>
  );
};
