import { useFormikContext } from 'formik';
import { useState, useEffect, useMemo } from 'react';

import { JiraAutocompleteDropdown } from 'features/jira_project/modal/sections/project_and_issues/jira_autocomplete_dropdown';
import {
  JiraAutoCompleteResult,
  JiraProjectModalFormFields,
  JiraProjectModalFormValues,
} from 'features/jira_project/modal/types';

import { getKey } from './advanced_filters/utils';
import { updateParentIssueIdsOrKeys } from './utils';

const ParentFiltersMultiInput = () => {
  const { values, setFieldValue: setFormikFieldValue } = useFormikContext<JiraProjectModalFormValues>();
  const parentIssueIdsOrKeys = useMemo(() => values[JiraProjectModalFormFields.ParentIssueIdsOrKeys] || [], [values]);
  const [dropdowns, setDropdowns] = useState<Array<number>>([0]);

  useEffect(() => {
    const newDropdowns =
      parentIssueIdsOrKeys.length === 0 ? [0] : Array.from({ length: parentIssueIdsOrKeys.length + 1 }, (_, i) => i);
    setDropdowns(newDropdowns);
  }, [parentIssueIdsOrKeys]);

  const setFieldValue = ({ newValue, index }: { newValue?: JiraAutoCompleteResult; index?: number }) => {
    const newParentIssueIdsOrKeys = updateParentIssueIdsOrKeys(parentIssueIdsOrKeys, newValue, index);

    setFormikFieldValue(JiraProjectModalFormFields.ParentIssueIdsOrKeys, newParentIssueIdsOrKeys);
  };

  return (
    <>
      {dropdowns.map((dropdownIndex) => (
        <JiraAutocompleteDropdown
          selectedValues={parentIssueIdsOrKeys}
          setFieldValue={setFieldValue}
          parent="parent"
          key={getKey(dropdownIndex, parentIssueIdsOrKeys)}
          index={dropdownIndex}
        />
      ))}
    </>
  );
};

export default ParentFiltersMultiInput;
