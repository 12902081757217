import {
  checkSolid,
  IconDefinition,
  LpIcon,
  pauseSolid,
  questionCircleSolid,
  rectangleWideSolid,
} from 'features/common/lp_icon';
import { ScheduleSummaryItem } from 'features/common/schedule_bar/types';

import { ScheduleBarHoverText } from './enums';
import { AssignmentTooltipContent, ContainerTooltipContent, TaskToolTipContent } from './types';

export const ToolTipElement = ({
  icon,
  dateText = '',
  toolTipText,
}: {
  icon: IconDefinition;
  dateText?: string;
  toolTipText: string;
}) => {
  const toolTipDisplayText = dateText ? `${dateText} ${toolTipText}` : toolTipText;

  return (
    <span data-testid="tool_tip_element">
      <LpIcon data-testid="tool_tip_icon" icon={icon} className="schedule-bar-tooltip__icon" />
      {toolTipDisplayText}
    </span>
  );
};

export const getTaskTooltipContent = ({
  isOnHold,
  isEffectivelyOnHold,
  isDone,
  formattedDoneDate,
  taskNeedsAttention,
  hoverDateRange,
  isItemAtRisk,
  assignedToDoneIteration,
}: TaskToolTipContent) => {
  if (isDone) {
    return <ToolTipElement icon={checkSolid} dateText={formattedDoneDate} toolTipText={ScheduleBarHoverText.Done} />;
  }

  if (isOnHold) {
    return <ToolTipElement icon={pauseSolid} toolTipText={ScheduleBarHoverText.OnHold} />;
  }

  if (isEffectivelyOnHold) {
    if (assignedToDoneIteration) {
      return <ToolTipElement icon={pauseSolid} toolTipText={ScheduleBarHoverText.NotScheduledIterationDone} />;
    }
    return <ToolTipElement icon={pauseSolid} toolTipText={ScheduleBarHoverText.EffectivelyOnHold} />;
  }

  if (taskNeedsAttention) {
    return (
      <ToolTipElement
        icon={questionCircleSolid}
        dateText={hoverDateRange}
        toolTipText={ScheduleBarHoverText.NeedsAttention}
      />
    );
  }

  if (isItemAtRisk) {
    return (
      <ToolTipElement icon={rectangleWideSolid} dateText={hoverDateRange} toolTipText={ScheduleBarHoverText.AtRisk} />
    );
  }

  return (
    <ToolTipElement icon={rectangleWideSolid} dateText={hoverDateRange} toolTipText={ScheduleBarHoverText.Finish} />
  );
};

export const getAssignmentTooltipContent = ({
  hoverDateRange,
  isItemAtRisk,
  highEffort,
  isDone,
  isParentDone,
}: AssignmentTooltipContent) => {
  if ((isDone || isParentDone) && highEffort) {
    return <span>{ScheduleBarHoverText.NeedRemainingEstimate}</span>;
  }

  if (isItemAtRisk) {
    return (
      <ToolTipElement icon={rectangleWideSolid} dateText={hoverDateRange} toolTipText={ScheduleBarHoverText.AtRisk} />
    );
  }

  if (!highEffort) {
    return (
      <ToolTipElement
        icon={questionCircleSolid}
        dateText={hoverDateRange}
        toolTipText={ScheduleBarHoverText.NeedsAttention}
      />
    );
  }

  return (
    <ToolTipElement icon={rectangleWideSolid} dateText={hoverDateRange} toolTipText={ScheduleBarHoverText.Finish} />
  );
};

export const getContainerTooltipContent = ({
  isDone,
  isItemAtRisk,
  isOnHold,
  isEffectivelyOnHold,
  formattedDoneDate,
  dateRangeText,
  isContainerScheduled,
}: ContainerTooltipContent) => {
  if (isDone) {
    return <ToolTipElement icon={checkSolid} dateText={formattedDoneDate} toolTipText={ScheduleBarHoverText.Done} />;
  }

  if (isOnHold) {
    return <ToolTipElement icon={pauseSolid} toolTipText={ScheduleBarHoverText.OnHold} />;
  }

  if (isEffectivelyOnHold) {
    return <ToolTipElement icon={pauseSolid} toolTipText={ScheduleBarHoverText.EffectivelyOnHold} />;
  }

  if (isItemAtRisk) {
    return (
      <ToolTipElement icon={rectangleWideSolid} dateText={dateRangeText} toolTipText={ScheduleBarHoverText.AtRisk} />
    );
  }

  if (isContainerScheduled) {
    return (
      <ToolTipElement icon={rectangleWideSolid} dateText={dateRangeText} toolTipText={ScheduleBarHoverText.Finish} />
    );
  } else if (dateRangeText) {
    return (
      <ToolTipElement
        icon={questionCircleSolid}
        dateText={dateRangeText}
        toolTipText={ScheduleBarHoverText.NeedsAttention}
      />
    );
  } else {
    return <ToolTipElement icon={questionCircleSolid} toolTipText={ScheduleBarHoverText.NeedsAttention} />;
  }
};

export const getDateRangeText = ({
  expectedFinish,
  formatLocalDate,
  latestFinish,
}: {
  expectedFinish?: string | null;
  formatLocalDate: (dateString: string) => string;
  latestFinish?: string | null;
}) => {
  if (!latestFinish || !expectedFinish) {
    return '';
  }

  const formattedExpectedFinish = formatLocalDate(expectedFinish);
  const formattedLatestFinish = formatLocalDate(latestFinish);

  if (formattedExpectedFinish === formattedLatestFinish) {
    return formattedExpectedFinish;
  }

  return `${formattedExpectedFinish} - ${formattedLatestFinish}`;
};

export const getIsLateOrLateRisk = (isLate: boolean, isLateRisk: boolean) => isLate || isLateRisk;

export const getHasClippedEffortForScheduleSummaryItem = (item: ScheduleSummaryItem) => {
  const clippedEffort = item.clippedEffort ?? 0;
  return clippedEffort > 0;
};
