export const TempoLogoSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 142.84">
    <path
      fill="#1d1d1b"
      d="m562.86,122.4c-21.95,0-37.14-15.33-37.14-37.14s15.19-37.14,37.14-37.14,37.14,15.33,37.14,37.14-15.19,37.14-37.14,37.14Zm-19.41-37.14c0,13.93,7.46,22.51,19.41,22.51s19.41-8.58,19.41-22.51-7.46-22.51-19.41-22.51-19.41,8.58-19.41,22.51Z"
    />
    <path
      fill="#1d1d1b"
      d="m446.15,142.28V50.09h17.02v6.33c5.49-5.35,12.94-8.3,21.67-8.3,18.85,0,31.79,15.33,31.79,37.14s-13.51,37.14-33.2,37.14c-7.74,0-14.49-2.53-19.56-7.17v27.06h-17.73Zm17.02-57.02c0,13.93,6.89,22.51,17.87,22.51s17.87-8.58,17.87-22.51-6.89-22.51-17.87-22.51-17.87,8.58-17.87,22.51Z"
    />
    <path
      fill="#1d1d1b"
      d="m415.08,78.36c0-5.21-.84-8.44-2.53-10.83-1.97-2.81-5.21-4.5-9.14-4.5-4.36,0-8.02,1.55-10.69,4.5-2.81,3.1-4.36,7.74-4.36,15.05v37.84h-17.73v-42.06c0-5.77-1.12-9.43-3.38-11.82-1.97-2.25-4.78-3.52-8.3-3.52-3.94,0-7.32,1.27-9.85,3.66-3.38,3.1-5.21,8.02-5.21,15.9v37.84h-17.73V50.09h16.88v6.05c4.78-5.06,12.24-8.02,21.24-8.02s15.9,3.8,20.12,10.13c5.21-6.33,13.79-10.13,24.34-10.13,7.17,0,13.37,2.53,17.59,6.89,4.08,4.22,6.47,10.13,6.47,19.98v45.44h-17.73v-42.06Z"
    />
    <path
      fill="#1d1d1b"
      d="m280.12,122.4c-20.96,0-35.45-15.33-35.45-37.14s14.21-37.14,34.61-37.14,34.61,15.33,34.61,37.14c0,2.25-.14,4.5-.42,6.61h-51.07c1.27,9.99,7.88,15.9,17.73,15.9,6.89,0,11.96-3.09,14.35-8.58h18.43c-3.52,13.93-15.9,23.21-32.78,23.21Zm-17.73-44.32h33.48c-1.41-9.57-7.32-15.33-16.6-15.33s-15.48,5.63-16.88,15.33Z"
    />
    <path fill="#1d1d1b" d="m204.85,120.43V37.99h-30.25v-16.04h78.78v16.04h-30.25v82.44h-18.29Z" />
    <path
      fill="#f54d42"
      d="m76.17,0L30.49,45.68l-15.25-15.25L0,45.67l15.24,15.24c8.42,8.42,22.08,8.42,30.51,0L91.42,15.24,76.17,0Z"
    />
    <path
      fill="#9664fe"
      d="m4.33,99.71l62.4,16.72-5.58,20.83,20.83,5.58,5.58-20.82c3.08-11.51-3.75-23.34-15.25-26.42l-62.39-16.72-5.57,20.83Z"
    />
    <path
      fill="#ffcc24"
      d="m126.66,112.24l-16.72-62.4,20.83-5.58-5.58-20.83-20.82,5.58c-11.51,3.08-18.34,14.91-15.25,26.42l16.72,62.39,20.82-5.59Z"
    />
  </svg>
);
