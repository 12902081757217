import { noop } from 'lodash';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { PackageStatus } from 'daos/enums';
import { ExternalIntegrationDao } from 'daos/external_integration';
import { SourceSystem, TaskPushSetting } from 'daos/external_integration_enums';
import { JiraExternalIntegrationDao } from 'daos/jira_external_integration';
import {
  ConnectionInformation,
  JiraIntegration,
  JiraMapping,
  JiraOauthAccount,
  JiraProjectDetails,
} from 'daos/model_types';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import {
  getDateDiffFromIntegrationDate,
  getEstimationType,
  getNumberOfPulledFields,
  getNumberOfPushedFields,
  getPushedIssueTypes,
  getStoryPointsSchemeActionForAvo,
  getUpdatedAvoAttributes,
  getWorklogValues,
  hasOneWaySync,
  hasTwoWaySync,
} from 'features/jira_project/modal/analytics/analytics_utils';
import { JiraProjectModalProviderProps } from 'features/jira_project/modal/context/types';
import { JiraProjectModalContext } from 'features/jira_project/modal/jira_project_modal_context';
import { getJiraModalTabs } from 'features/jira_project/modal/sections/section_helpers';
import {
  FieldMappingValue,
  IssueTypeValue,
  JiraAdvancedFilter,
  JiraProject,
  JiraProjectModalFormFields,
  JiraProjectModalFormValues,
  JiraTabKey,
  lpNameSystemMapping,
  SchemeDisplayNames,
  TabNames,
} from 'features/jira_project/modal/types';
import {
  getCurrentTabName,
  getJiraProjectModalCreatePayload,
  getJiraProjectModalUpdatePayload,
  getPreviousTab,
  sortStoryPointsOnPageLeave,
} from 'features/jira_project/modal/utils';
import { EditableStoryPoints } from 'features/story_points/editable_story_points_list/types';
import { useStoryPointSchemesWithDefaultAndStoryPointValues } from 'features/story_points/story_points_scheme_modal/use_story_point_schemes_with_default_and_story_point_values';
import useHashParams from 'hooks/use_hash_params';
import { awaitRequestFinish } from 'lib/api';
import { avoClient, IntegrationCompletedProperties } from 'lib/avo/client';
import { frontend } from 'lib/urls';
import { getPackageStatusForItemId } from 'redux/entities/selectors/item';

const { segmentKey } = window as any;

export const JiraProjectModalProvider = ({
  children,
  packageId,
  integrationId,
  setShowJiraProjectModal,
  itemId,
  canModify,
  hasFieldMappingWarning = false,
  setShouldActivatePushToJiraButton,
}: JiraProjectModalProviderProps) => {
  const packageStatus = useSelector((state) => getPackageStatusForItemId(state, packageId)) ?? PackageStatus.SCHEDULED;
  const dispatch = useDispatch();
  const organizationId = useSelector((state) => getCurrentOrganizationId(state));
  const workspaceId = useSelector((state) => getCurrentWorkspaceId(state));
  const history = useHistory();
  const isEditMode = Boolean(itemId && integrationId);
  const [completedTabs, setCompletedTabs] = useState<Record<JiraTabKey, boolean>>({
    [JiraTabKey.Welcome]: true,
    [JiraTabKey.JiraInstance]: isEditMode,
    [JiraTabKey.ProjectAndIssuesFilter]: isEditMode,
    [JiraTabKey.SyncSettings]: isEditMode,
    [JiraTabKey.FieldMapping]: isEditMode,
    [JiraTabKey.RemainingEstimates]: isEditMode,
    [JiraTabKey.Launch]: false,
  });
  const tabs = getJiraModalTabs({ isEditMode });
  const { panelSection: activeTab } = useHashParams() as { panelSection: JiraTabKey };
  const { previousTabKey } = getPreviousTab({ activeTab, tabs });

  const [jiraProjectName, setJiraProjectName] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoadingContent, setIsLoadingContent] = useState(false);
  const [isLoadingMappingScreenData, setIsLoadingMappingScreenData] = useState(false);
  const [showConfirmCloseModal, setShowConfirmCloseModal] = useState(false);
  const [formError, setFormError] = useState<string>();
  const [jiraOAuthAccounts, setJiraOAuthAccounts] = useState<ReadonlyArray<JiraOauthAccount>>([]);
  const [jiraIntegrations, setJiraIntegrations] = useState<ReadonlyArray<JiraIntegration>>([]);
  const [jiraAdvancedFilters, setJiraAdvancedFilters] = useState<ReadonlyArray<JiraAdvancedFilter>>([]);
  const [jiraProjects, setJiraProjects] = useState<ReadonlyArray<JiraProject>>([]);
  const [hasSortedStoryPoints, setHasSortedStoryPoints] = useState(false);
  const [storyPointsToSort, setStoryPointsToSort] = useState<Array<EditableStoryPoints>>([]);
  const [isLoadingJiraProjects, setIsLoadingJiraProjects] = useState<boolean>(false);
  const [jiraProjectDetails, setJiraProjectDetails] = useState<JiraProjectDetails>();
  const [jiraProjectMappingFieldData, setJiraProjectMappingFieldData] = useState<ReadonlyArray<FieldMappingValue>>([]);
  const [jiraProjectMappingIssueTypes, setJiraProjectMappingIssueTypes] = useState<ReadonlyArray<IssueTypeValue>>([]);
  const [jiraToLpFieldMappingTableIsExpanded, setJiraToLpFieldMappingTableIsExpanded] = useState(true);
  const [lpToJiraFieldMappingTableIsExpanded, setLpToJiraFieldMappingTableIsExpanded] = useState(true);
  const [selectedSchemeActionDisplayName, setSelectedSchemeActionDisplayName] = useState<
    SchemeDisplayNames | string | undefined
  >(undefined);
  const [connectionInformation, setConnectionInformation] = useState<ConnectionInformation>();
  const [issueCount, setIssueCount] = useState<number>();
  const [userCount, setUserCount] = useState<number>();
  const [credentialsError, setCredentialsError] = useState<string | undefined>();
  const [canModifyCostCode, setCanModifyCostCode] = useState<boolean>(true);
  const [canModifyStoryPoints, setCanModifyStoryPoints] = useState<boolean>(true);
  const [canSelectLowerHierarchicalLevel, setCanSelectLowerHierarchicalLevel] = useState<{
    isFlatListDisabled: boolean;
    isEpicBasedDisabled: boolean;
  }>({
    isFlatListDisabled: false,
    isEpicBasedDisabled: false,
  });
  const [initialValuesForAnalyticsOnly, setInitialValuesForAnalyticsOnly] = useState<JiraProjectModalFormValues>();
  const [addedLpFieldMappingRowItems, setAddedLpFieldMappingRowItems] = useState<Set<FieldMappingValue>>(
    isEditMode ? new Set() : new Set(lpNameSystemMapping)
  );
  const [addedJiraFieldMappingRowItems, setAddedJiraFieldMappingRowItems] = useState<Set<FieldMappingValue>>(new Set());
  const isPremiumJiraUser = jiraProjectDetails?.hasParentAncestry ?? false;
  const { schemesWithStoryPoints, currentStoryPointSchemeOwner: storyPointSchemeOwner } =
    useStoryPointSchemesWithDefaultAndStoryPointValues(itemId ?? 0, isEditMode, canModifyStoryPoints);

  const avoIntegrationCanceled = (activeTab: JiraTabKey) => {
    if (segmentKey) {
      avoClient?.integrationCancelled({
        integrationName: SourceSystem.JIRA,
        stepName: getCurrentTabName(activeTab),
      });
    }
  };

  const handlePanelClose = useCallback(
    ({ itemId, isIntegrationCancelled = true }: { itemId?: number; isIntegrationCancelled?: boolean }) => {
      setShowJiraProjectModal(false);
      if (itemId) {
        history.push(frontend.projectProject.url({ itemId, organizationId, workspaceId }));
      } else {
        history.push(location.pathname);
      }
      if (isIntegrationCancelled) {
        avoIntegrationCanceled(activeTab);
      }
    },
    [setShowJiraProjectModal, activeTab, history, organizationId, workspaceId]
  );

  const handleConfirmClose = () => {
    setShowConfirmCloseModal(true);
  };

  const handleCloseButtonClick = () => {
    if (!completedTabs[JiraTabKey.JiraInstance]) {
      handlePanelClose({});
    } else {
      handleConfirmClose();
    }
  };

  const handleBackView = () => {
    history.push(`?packageId=${packageId}#panelSection=${previousTabKey}`);
    sortStoryPointsOnPageLeave({
      activeTab,
      storyPoints: storyPointsToSort,
      setHasSortedStoryPoints,
    });
  };

  const handleNextView = useCallback(
    ({ key, stepCompleted }: { key: JiraTabKey; stepCompleted: TabNames }) => {
      sortStoryPointsOnPageLeave({
        activeTab,
        storyPoints: storyPointsToSort,
        setHasSortedStoryPoints,
      });
      setCompletedTabs((prev) => ({ ...prev, [activeTab]: true }));
      history.push(`?packageId=${packageId}#panelSection=${key}`);
      if (segmentKey) {
        avoClient?.integrationStepCompleted({
          integrationName: SourceSystem.JIRA,
          stepName: stepCompleted,
          packageId: packageId.toString(),
          workspaceId: workspaceId.toString(),
        });
      }
    },
    [activeTab, history, packageId, storyPointsToSort, workspaceId]
  );

  const handleSubmitFieldMapping = useCallback(
    (formValues: JiraProjectModalFormValues) => {
      const credentialsId = formValues.oauthCredentialsId;

      const integrationCompletedProps: IntegrationCompletedProperties = {
        integrationName: SourceSystem.JIRA,
        numberOfIssues: issueCount,
        numberOfIssueStatuses: formValues[JiraProjectModalFormFields.IssueStatusIds].length,
        issuesCreatedAfter: getDateDiffFromIntegrationDate(formValues[JiraProjectModalFormFields.IssueCreatedDate]),
        oneWaySync: hasOneWaySync(formValues[JiraProjectModalFormFields.FieldMappings]),
        twoWaySync: hasTwoWaySync(formValues[JiraProjectModalFormFields.FieldMappings]),
        remainingEstimate: formValues[JiraProjectModalFormFields.HighEstimate],
        numberOfPulledFields: getNumberOfPulledFields(formValues[JiraProjectModalFormFields.FieldMappings]),
        numberOfPushedFields: getNumberOfPushedFields(formValues[JiraProjectModalFormFields.FieldMappings]),
        pushedIssueTypes: getPushedIssueTypes(
          formValues[JiraProjectModalFormFields.IssueTypeIds],
          jiraProjectDetails?.types
        ),
        costCode: formValues[JiraProjectModalFormFields.CostCodeId],
        numberOfResources: userCount ?? 0,
        subFolderOrganization: formValues[JiraProjectModalFormFields.TaskGroupHierarchy],
        syncIssuePriorityOrder: formValues[JiraProjectModalFormFields.RankIssues],
        estimateType: getEstimationType(formValues[JiraProjectModalFormFields.EstimationType]),
        storypointScheme: getStoryPointsSchemeActionForAvo(
          formValues[JiraProjectModalFormFields.EstimationType],
          formValues[JiraProjectModalFormFields.SelectedExistingSchemeToCopy],
          formValues[JiraProjectModalFormFields.ExistingSchemeId]
        ),
        manualPush: formValues[JiraProjectModalFormFields.TaskPushSetting] === TaskPushSetting.Manual,
        pullWorklogs: getWorklogValues(
          formValues[JiraProjectModalFormFields.SyncJiraWorklog],
          formValues[JiraProjectModalFormFields.SyncTimesheetWorklog]
        ),
      };

      if (!credentialsId) {
        return;
      }

      const payload = getJiraProjectModalCreatePayload({
        packageId,
        jiraProjectName,
        formValues,
      });

      const jiraFieldMappingSubmitFn = JiraExternalIntegrationDao.create({ organizationId, workspaceId }, payload);
      const { uuid } = dispatch(jiraFieldMappingSubmitFn);
      setIsSubmitting(true);

      dispatch(
        awaitRequestFinish<JiraMapping>(uuid, {
          onSuccess: ({ data }) => {
            setFormError(undefined);
            handlePanelClose({ itemId: data.itemId, isIntegrationCancelled: false });
            if (segmentKey) {
              avoClient?.integrationCompleted(integrationCompletedProps);
            }
          },
          onError: ({ errors }) => {
            if (errors[0]) {
              setFormError(errors[0]?.detail ?? errors[0].title);
            }
          },
          onFinish: () => {
            setIsSubmitting(false);
          },
        })
      );
    },
    [
      packageId,
      jiraProjectName,
      organizationId,
      workspaceId,
      dispatch,
      handlePanelClose,
      issueCount,
      userCount,
      jiraProjectDetails?.types,
    ]
  );

  const fetchLatestJiraSyncSettings = useCallback(
    (externalIntegrationId: number) => {
      const fetchJiraSyncSettingsAction = ExternalIntegrationDao.fetch(
        { organizationId, workspaceId, externalIntegrationId },
        {
          include: {
            jiraSyncSettings: true,
          },
        }
      );
      dispatch(fetchJiraSyncSettingsAction);
    },
    [organizationId, workspaceId, dispatch]
  );

  const handleUpdateJiraExternalIntegration = useCallback(
    (formValues: JiraProjectModalFormValues) => {
      if (!integrationId) {
        return;
      }
      const payload = getJiraProjectModalUpdatePayload({
        formValues,
      });

      const jiraExternalIntegrationUpdateFn = JiraExternalIntegrationDao.update(
        { organizationId, workspaceId, externalIntegrationId: integrationId },
        payload
      );

      const { uuid } = dispatch(jiraExternalIntegrationUpdateFn);
      setIsSubmitting(true);

      dispatch(
        awaitRequestFinish(uuid, {
          onSuccess: () => {
            setFormError(undefined);
            handlePanelClose({ itemId, isIntegrationCancelled: false });
            fetchLatestJiraSyncSettings(integrationId);

            if (segmentKey) {
              avoClient?.integrationUpdated(
                getUpdatedAvoAttributes({
                  formValues,
                  initialValues: initialValuesForAnalyticsOnly,
                  issueTypes: jiraProjectDetails?.types,
                  workspaceId,
                  packageId,
                })
              );
            }
          },
          onError: ({ errors }) => {
            if (errors[0]) {
              setFormError(errors[0]?.detail ?? errors[0].title);
            }
          },
          onFinish: () => {
            setIsSubmitting(false);
            if (hasFieldMappingWarning && setShouldActivatePushToJiraButton) {
              setShouldActivatePushToJiraButton(true);
            }
          },
        })
      );
    },
    [
      integrationId,
      organizationId,
      workspaceId,
      dispatch,
      handlePanelClose,
      itemId,
      fetchLatestJiraSyncSettings,
      initialValuesForAnalyticsOnly,
      jiraProjectDetails?.types,
      packageId,
      hasFieldMappingWarning,
      setShouldActivatePushToJiraButton,
    ]
  );
  const submitHandler = (values: JiraProjectModalFormValues) => {
    if (isEditMode) {
      return handleUpdateJiraExternalIntegration(values);
    }
    switch (activeTab) {
      case JiraTabKey.Welcome:
        return handleNextView({ key: JiraTabKey.JiraInstance, stepCompleted: TabNames.Welcome });
      case JiraTabKey.JiraInstance:
        return handleNextView({
          key: JiraTabKey.ProjectAndIssuesFilter,
          stepCompleted: TabNames.JiraInstanceAndCredentials,
        });
      case JiraTabKey.ProjectAndIssuesFilter:
        return handleNextView({ key: JiraTabKey.SyncSettings, stepCompleted: TabNames.ProjectAndIssuesFilter });
      case JiraTabKey.SyncSettings:
        return handleNextView({ key: JiraTabKey.FieldMapping, stepCompleted: TabNames.SyncSettings });
      case JiraTabKey.FieldMapping:
        return handleNextView({ key: JiraTabKey.RemainingEstimates, stepCompleted: TabNames.FieldMapping });
      case JiraTabKey.RemainingEstimates:
        return handleNextView({ key: JiraTabKey.Launch, stepCompleted: TabNames.RemainingEstimates });
      case JiraTabKey.Launch:
        return handleSubmitFieldMapping(values);
      default:
        return noop;
    }
  };

  return (
    <JiraProjectModalContext.Provider
      value={{
        activeTab,
        submitHandler,
        isSubmitting,
        isLoadingContent,
        setIsLoadingContent,
        isLoadingMappingScreenData,
        setIsLoadingMappingScreenData,
        handleBackView,
        tabs,
        completedTabs,
        packageId,
        integrationId,
        itemId,
        isEditMode,
        packageStatus,
        handleCloseButtonClick,
        formError,
        setFormError,
        previousTabKey,
        setJiraProjectName,
        showConfirmCloseModal,
        handlePanelClose,
        setShowConfirmCloseModal,
        jiraOAuthAccounts,
        setJiraOAuthAccounts,
        jiraIntegrations,
        setJiraIntegrations,
        jiraProjects,
        setJiraProjects,
        jiraProjectDetails,
        setJiraProjectDetails,
        jiraProjectMappingFieldData,
        setJiraProjectMappingFieldData,
        jiraProjectMappingIssueTypes,
        setJiraProjectMappingIssueTypes,
        connectionInformation,
        setConnectionInformation,
        issueCount,
        setIssueCount,
        userCount,
        setUserCount,
        credentialsError,
        setCredentialsError,
        canModify,
        canModifyCostCode,
        setCanModifyCostCode,
        setInitialValuesForAnalyticsOnly,
        initialValuesForAnalyticsOnly,
        addedLpFieldMappingRowItems,
        setAddedJiraFieldMappingRowItems,
        addedJiraFieldMappingRowItems,
        setAddedLpFieldMappingRowItems,
        isPremiumJiraUser,
        setIsLoadingJiraProjects,
        isLoadingJiraProjects,
        canSelectLowerHierarchicalLevel,
        setCanSelectLowerHierarchicalLevel,
        selectedSchemeActionDisplayName,
        setSelectedSchemeActionDisplayName,
        schemesWithStoryPoints,
        canModifyStoryPoints,
        setCanModifyStoryPoints,
        storyPointSchemeOwner,
        hasFieldMappingWarning,
        setShouldActivatePushToJiraButton,
        hasSortedStoryPoints,
        setStoryPointsToSort,
        storyPointsToSort,
        setHasSortedStoryPoints,
        jiraToLpFieldMappingTableIsExpanded,
        setJiraToLpFieldMappingTableIsExpanded,
        lpToJiraFieldMappingTableIsExpanded,
        setLpToJiraFieldMappingTableIsExpanded,
        jiraAdvancedFilters,
        setJiraAdvancedFilters,
      }}
    >
      {children}
    </JiraProjectModalContext.Provider>
  );
};
