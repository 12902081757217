import { ListWidgetType } from 'daos/enums';
import { WidgetWithData } from 'daos/model_types';
import { RenderedWidget } from 'features/dashboards_v2/widget/index';
import { ListWidgetTable } from 'features/dashboards_v2/widget/widgets/list/table';
import { useWidgetDateRange } from 'features/dashboards_v2/widget/widgets/list/use_widget_date_range';
import { ItemListWidgetData } from 'features/dashboards_v2/widget/widgets/reports/types';

import { ItemListWidgetContext } from './context';

import './list.scss';

const ItemListWidgetBody = ({ widget }: { widget: WidgetWithData }) => {
  const widgetData = widget.data as ItemListWidgetData;
  const dateRange = useWidgetDateRange(widgetData.rowData);

  return (
    <ItemListWidgetContext.Provider value={{ dateRange }}>
      <ListWidgetTable widget={widget} />
    </ItemListWidgetContext.Provider>
  );
};

const PeopleListWidgetBody = ({ widget }: { widget: WidgetWithData }) => {
  return <ListWidgetTable widget={widget} />;
};

export const ListWidget = ({ widget }: { widget: WidgetWithData }) => {
  const {
    config: { listType },
  } = widget;

  const defaultRender = <RenderedWidget noContent />;

  switch (listType) {
    case ListWidgetType.Assignment:
    case ListWidgetType.Folders:
    case ListWidgetType.Packages:
    case ListWidgetType.Projects:
    case ListWidgetType.Tasks:
      return <RenderedWidget body={<ItemListWidgetBody widget={widget} />} />;

    case ListWidgetType.Users:
      return <RenderedWidget body={<PeopleListWidgetBody widget={widget} />} />;

    case ListWidgetType.Picklists:
    default:
      return defaultRender;
  }
};
