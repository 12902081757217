import { url } from 'lib/url_builder';

export const backend = {
  login: url('/v1/login'),
  loginSso: url('/v1/login/sso'),
  logout: url('/v1/logout'),
  forgotPassword: url('/v1/password-reset-tokens'),
  resetPassword: url('/v1/password-reset-tokens/:token(.+)'),
  register: url('/v1/registration'),
  emailConfirmations: url('/v1/email-confirmations'),
  emailConfirmationsVerify: url('/v1/email-confirmations/:token(.+)'),
  invitations: url('/v1/invitations'),
  invitationsAccept: url('/v1/invitations/:token'),
  guestInvitations: url('/v1/invitations/guest'),
  inAppNotificationsForCurrentWsUser: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/inAppNotifications/current'
  ),
  ablyAuth: url('v1/ably/auth'),
  academyImageDownload: url('v1/academy/images/:imageId(\\d+)/download'),
  academyCourses: url('v1/academy-courses'),
  academyCourse: url('v1/academy-courses/:courseId(\\d+)'),
  academyLessons: url('v1/academy-lessons'),
  academyLessonProgressions: url('v1/academy-lessons/:academyLessonId(\\d+)/academy-lesson-progress'),
  academyLessonProgress: url('v1/academy-lessons/:lessonId(\\d+)/academy-lesson-progress/:progressId(\\d+)'),
  academyOutpostTrackLesson: url('v1/academy-outpost-track-lessons'),
  academySearch: url('v1/academy-search'),
  academyTrackLessons: url('v1/academy-track-lessons/:name([a-z_]+)'),
  availabilityRanges: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/availability-ranges'),
  availabilityRange: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/availability-ranges/:availabilityRangeId(\\d+)'
  ),
  avatar: url('/v1/organizations/:organizationId(\\d+)/organization-users/:organizationUserId(\\d+)/avatar'),
  base: url('/v1'),
  favorites: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/favorites'),
  favorite: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/favorites/:favoriteId(\\d+)'),
  favoriteUpsert: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/favorites/upsert'),
  itemPins: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/item-pins'),
  itemPin: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/item-pins/:itemPinId(\\d+)'),
  portfolio: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/portfolio'),
  timesheet: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/workspace-users/:workspaceUserId(\\d+)/timesheet'
  ),
  timesheetEntries: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/workspace-users/:workspaceUserId(\\d+)/timesheet-entries'
  ),
  timesheetEntriesUpdateItem: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/workspace-users/:workspaceUserId(\\d+)/timesheet-entries/update-item'
  ),
  timesheetEntry: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/workspace-users/:workspaceUserId(\\d+)/timesheet-entries/:timesheetEntryId(\\d+)'
  ),
  timesheetEntryUpdateItem: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/workspace-users/:workspaceUserId(\\d+)/timesheet-entries/update-item/:timesheetEntryId(\\d+)'
  ),
  timesheetEntryLocks: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/timesheet-entry-locks'
  ),
  timesheetEntryLockExceptions: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/timesheet-entry-lock-exceptions'
  ),
  timesheetEntryLock: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/timesheet-entry-locks/:timesheetEntryLockId(\\d+)'
  ),
  timesheetEntryLockException: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/timesheet-entry-lock-exceptions/:timesheetEntryLockExceptionId(\\d+)'
  ),
  timesheetReview: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/timesheet-review'),
  timesheetReviewExport: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/timesheet-review/xlsx'
  ),
  timesheetReviewExportInfo: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/timesheet-review/xlsx/:jobId(\\S+)/export-info'
  ),
  timesheetDownloadFile: url(':exportUrl(\\S+)'),
  timesheetReviewExportPreview: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/timesheet-review/xlsx/preview'
  ),
  timesheetReviewExportHeaders: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/timesheet-review/xlsx/headers'
  ),
  timesheetReviewUsers: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/timesheet-review-users'
  ),
  timesheetReviewUsersBulk: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/timesheet-review-users/bulk-replace'
  ),
  timesheetReviewUser: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/timesheet-review-users/:timesheetReviewUserId(\\d+)'
  ),
  user: url('/v1/users/:userId(\\d+)'),
  userCurrent: url('/v1/users/current'),
  currentUserAuth: url('/v1/users/current/authSettings'),
  dashboards: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/dashboards'),
  dashboardImages: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/dashboardImages'),
  dashboardImage: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/dashboardImages/:s3Id/download'
  ),
  dashboardsInFocus: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/dashboards/in-focus'),
  dashboard: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/dashboards/:dashboardId(\\d+)'),
  dashboardGuest: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/dashboards/:dashboardId(\\d+)/guests/:dashboardGuestId(\\d+)'
  ),
  dashboardDuplicate: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/dashboards/:dashboardId(\\d+)/duplicate'
  ),
  dashboardByItemId: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/dashboards/items/:itemId(\\d+)'
  ),

  libraryResources: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/library'),
  libraryResourceDuplicate: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/library/library-resource/:libraryResourceId(\\d+)/duplicate'
  ),
  widgetGroups: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/dashboards/:dashboardId(\\d+)/widget-groups'
  ),
  widgetGroup: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/dashboards/:dashboardId(\\d+)/widget-groups/:widgetGroupId(\\d+)'
  ),
  widgetGroupDuplicate: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/dashboards/:dashboardId(\\d+)/widget-groups/:widgetGroupId(\\d+)/duplicate'
  ),
  widgets: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/dashboards/:dashboardId(\\d+)/widgets'
  ),
  widget: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/dashboards/:dashboardId(\\d+)/widgets/:widgetId'
  ),
  reportWidget: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/report-widgets/:widgetId(\\d+)'
  ),
  reportWidgetGridExport: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/report-widgets/:widgetId(\\d+)/grid/xlsx'
  ),
  widgetChanges: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/dashboards/:dashboardId(\\d+)/widgets/:widgetId/changes'
  ),
  widgetDelete: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/dashboards/:dashboardId(\\d+)/widgets/:widgetId/deleted'
  ),
  widgetDuplicate: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/dashboards/:dashboardId(\\d+)/widgets/:widgetId/duplicate'
  ),
  widgetGridExport: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/dashboards/:dashboardId(\\d+)/widgets/:widgetId/grid/xlsx'
  ),
  widgetPeopleListGridExport: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/dashboards/:dashboardId(\\d+)/widgets/:widgetId/people-list/xlsx'
  ),
  widgetItemList: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/dashboards/:dashboardId(\\d+)/widgets/:widgetId/item-list'
  ),
  widgetPeopleList: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/dashboards/:dashboardId(\\d+)/widgets/:widgetId/people-list'
  ),
  tableReportWidgetItemList: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/report-widgets/:widgetId(\\d+)/item-list'
  ),
  tableReportWidgetPeopleList: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/report-widgets/:widgetId(\\d+)/people-list'
  ),
  tableReportWidgetPeopleExcelExport: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/report-widgets/:widgetId(\\d+)/people-list/xlsx'
  ),
  widgetItems: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/dashboards/:dashboardId(\\d+)/widgets/:widgetId/items'
  ),
  widgetMetrics: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/dashboards/:dashboardId(\\d+)/widgets/:widgetId/metrics'
  ),
  widgetTaskBoard: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/dashboards/:dashboardId(\\d+)/widgets/:widgetId/task-board'
  ),
  widgetWorkload: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/dashboards/:dashboardId(\\d+)/widgets/:widgetId/workload'
  ),

  widgetIntake: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/dashboards/:dashboardId(\\d+)/widgets/:widgetId/intake'
  ),
  widgetIntakeFormCreate: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/dashboards/:dashboardId(\\d+)/widgets/:widgetId/intake/item'
  ),
  workspaceUsers: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/workspace-users'),
  workspaceUser: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/workspace-users/:workspaceUserId(\\d+)'
  ),
  workspaceUserAccess: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/workspace-users/:workspaceUserId(\\d+)/access'
  ),
  userAnalyticsIdentify: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/user-analytics/identify'
  ),
  userWorkspaceSettings: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/user-settings'),

  myWork: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/workspace-users/:workspaceUserId(\\d+)/my-work'
  ),
  wsUserFieldValues: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/workspace-users/:workspaceUserId(\\d+)/field-values'
  ),
  wsUserFieldValue: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/workspace-users/:workspaceUserId(\\d+)/field-values/:fieldValueId(\\d+)'
  ),
  groups: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/groups'),
  group: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/groups/:groupId(\\d+)'),
  groupAccess: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/groups/:groupId(\\d+)/access'
  ),
  groupsAvailability: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/groups/availability'),
  groupReservations: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/reservations'),
  groupAllocation: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/reservations/:id(\\d+)'),
  groupReservationSummary: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/reservations/summary'
  ),
  groupWorkload: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/groups/:groupId(\\d+)/workload'
  ),
  wsUserGroups: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/groups/:groupId(\\d+)/workspace-user-groups'
  ),
  wsUserGroupsBulk: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/groups/:groupId(\\d+)/workspace-user-groups/bulk'
  ),
  wsUserGroup: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/groups/:groupId(\\d+)/workspace-user-groups/:wsUserGroupId(\\d+)'
  ),
  duplicate: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/items/:itemId(\\d+)/duplicate'),
  duplicateBulk: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/items/bulk/duplicate'),
  itemAclUpsert: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/items/:itemId(\\d+)/item-acls/upsert'
  ),
  implicitGroupItemAcls: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/items/:itemId(\\d+)/item-acls/group/:groupId/implicit'
  ),
  implicitWorkspaceUserItemAcls: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/items/:itemId(\\d+)/item-acls/workspace-user/:workspaceUserId(\\d+)/implicit'
  ),
  explicitGroupItemAcls: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/items/:itemId(\\d+)/item-acls/group/:groupId(\\d+)/explicit'
  ),
  explicitWorkspaceUserItemAcls: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/items/:itemId(\\d+)/item-acls/workspace-user/:workspaceUserId(\\d+)/explicit'
  ),
  taskStatuses: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/task-statuses'),
  taskStatus: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/task-statuses/:taskStatusId(\\d+)'
  ),
  estimate: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/items/:itemId(\\d+)/estimates/:estimateId(\\d+)'
  ),
  costCodes: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/cost-codes'),
  costCode: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/cost-codes/:costCodeId(\\d+)'),
  rateSheets: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/rate-sheets'),
  rateSheet: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/rate-sheets/:rateSheetId(\\d+)'
  ),
  rateSheetRules: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/rate-sheets/:rateSheetId(\\d+)/rate-rules'
  ),
  rateSheetRule: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/rate-sheets/:rateSheetId(\\d+)/rate-rules/:rateRuleId(\\d+)'
  ),
  nonMemberUser: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/:userType([a-z|A-Z]+)'),
  fieldImages: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/fieldImages'),
  fieldImage: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/fieldImages/:s3Id/download'),
  intakeFiles: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/intakeFiles'),
  fields: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/fields'),
  field: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/fields/:fieldId(\\d+)'),
  picklistChoices: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/fields/:fieldId(\\d+)/picklist-choices'
  ),
  picklistChoice: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/fields/:fieldId(\\d+)/picklist-choices/:picklistChoiceId(\\d+)'
  ),
  fieldValues: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/items/:itemId(\\d+)/field-values'
  ),
  fieldValue: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/items/:itemId(\\d+)/field-values/:fieldValueId(\\d+)'
  ),
  items: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/items'),
  itemsGrid: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/items/grid'),
  itemsSearch: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/items/search'),
  itemAssignmentSwap: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/items/assignment-swap'
  ),
  itemEffectiveFieldValues: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/item-effective-field-values'
  ),
  itemExcelExport: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/items/grid/xlsx'),
  itemsBulk: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/items/bulk'),
  itemsBulkDelete: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/items/bulk-delete-async'),
  itemExcelTemplateDownload: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/items/bulk/xlsx/template'
  ),
  itemExcelTemplateUpload: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/items/:itemId(\\d+)/bulk/xlsx/import'
  ),
  item: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/items/:itemId(\\d+)'),
  itemAccess: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/items/:itemId(\\d+)/access'),
  itemChanges: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/items/:itemId(\\d+)/changes'),
  itemDiscussionsPosts: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/items/:itemId(\\d+)/discussions/posts'
  ),
  itemDiscussionsSubscriptions: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/items/:itemId(\\d+)/discussions/subscriptions'
  ),
  itemDiscussionsPost: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/items/:itemId(\\d+)/discussions/posts/:postId(\\d+)'
  ),
  myDiscussions: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/my_discussions'),
  discussion: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/discussions/:discussionId(\\d+)'
  ),
  discussionSubscriptions: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/discussions/:discussionId(\\d+)/subscriptions'
  ),
  discussionSubscription: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/discussions/:discussionId(\\d+)/subscriptions/:subscriptionId(\\d+)'
  ),
  discussionPostImages: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/discussionPostImages'
  ),
  discussionPostImage: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/discussionPostImages/:s3Id/download'
  ),
  itemFilesSearch: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/itemfiles'),
  itemFiles: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/items/:itemId(\\d+)/files'),
  itemFilesDownload: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/items/:itemId(\\d+)/files/:s3id/download'
  ),
  itemFilesMove: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/items/:itemId(\\d+)/files/moveBulk'
  ),
  itemFilesDelete: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/items/:itemId(\\d+)/files'
  ),
  itemDeleted: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/items/:itemId(\\d+)/deleted'),
  itemDeletedRecover: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/items/:itemId(\\d+)/deleted/:deletedId(\\d+)'
  ),
  itemWorkload: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/items/:itemId(\\d+)/workload'
  ),
  dependenciesBulk: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/dependencies/bulk'),
  dependenciesBulkForItemChain: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/dependencies/bulk-for-item-chain'
  ),
  dependency: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/items/:itemId(\\d+)/dependencies/:dependencyId(\\d+)'
  ),
  schedulingLimits: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/items/:itemId(\\d+)/scheduling-limits'
  ),
  schedulingLimit: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/items/:itemId(\\d+)/scheduling-limits/:schedulingLimitId(\\d+)'
  ),
  storyPointScheme: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/story-point-schemes/:storyPointSchemeId(\\d+)'
  ),
  storyPointSchemes: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/story-point-schemes'),
  storyPointSchemesDuplicate: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/story-point-schemes/duplicate'
  ),
  storyPointSchemeOwner: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/story-point-schemes/:storyPointSchemeId(\\d+)/owners/:storyPointSchemeOwnerId(\\d+)'
  ),
  storyPointSchemeOwners: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/story-point-schemes/:storyPointSchemeId(\\d+)/owners'
  ),
  taskStoryPoints: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/items/:itemId(\\d+)/task-story-points'
  ),
  system: url('/v1/system'),
  workspaces: url('/v1/organizations/:organizationId(\\d+)/workspaces'),
  workspacesMostRecent: url('/v1/organizations/:organizationId(\\d+)/workspaces/most-recent'), // use this route for disconnected and 'localStorage replacement' -- note: will return 404 if they are not in any ws's
  workspace: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)'),
  organizations: url('/v1/organizations'),
  organizationsMostRecent: url('/v1/organizations/most-recent'), // use this route for disconnected and 'localStorage replacement' -- note: will return 404 if they are not in any orgs
  organization: url('/v1/organizations/:organizationId(\\d+)'),
  organizationAccessBulk: url('/v1/organizations/:organizationId(\\d+)/accessBulk'),
  organizationPlans: url('/v1/organizations/:organizationId(\\d+)/plans'),
  organizationPlansRateData: url('/v1/organizations/:organizationId(\\d+)/plans/rate-data'),
  organizationBillingPortal: url('/v1/organizations/:organizationId(\\d+)/billing-portal'),
  organizationOneTimePurchase: url('/v1/organizations/:organizationId(\\d+)/one-time-purchase'),
  organizationInvitations: url('/v1/organizations/:organizationId(\\d+)/invitations'),
  organizationInvitation: url('/v1/organizations/:organizationId(\\d+)/invitations/:invitationId(\\d+)'),
  organizationUpgradePlan: url('/v1/organizations/:organizationId(\\d+)/upgrade-plan'),
  organizationDowngradePlan: url('/v1/organizations/:organizationId(\\d+)/downgrade-plan'),
  organizationTrialPremiumFeatures: url('/v1/organizations/:organizationId(\\d+)/trial-premium-features'),
  organizationCancelSubscriptionChange: url('/v1/organizations/:organizationId(\\d+)/cancel-subscription-change'),
  organizationUsers: url('/v1/organizations/:organizationId(\\d+)/organization-users'),
  organizationUsersBulk: url('/v1/organizations/:organizationId(\\d+)/organization-users/bulk'),
  organizationUser: url('/v1/organizations/:organizationId(\\d+)/organization-users/:organizationUserId(\\d+)'),
  plans: url('/v1/organizations/:organizationId(\\d+)/plans'),
  plansRateData: url('/v1/organizations/:organizationId(\\d+)/plans/rate-data'),

  organizationApiTokens: url('/v1/organizations/:organizationId(\\d+)/api-tokens'),
  organizationApiToken: url('/v1/organizations/:organizationId(\\d+)/api-tokens/:tokenId(\\d+)'),

  organizationUserEmailNotifications: url(
    '/v1/organizations/:organizationId(\\d+)/organization-users/:organizationUserId(\\d+)/integrations/email'
  ),
  organizationUserEmailNotificationsUpsert: url(
    '/v1/organizations/:organizationId(\\d+)/organization-users/:organizationUserId(\\d+)/integrations/email/upsert'
  ),
  organizationUserSlackIntegration: url(
    '/v1/organizations/:organizationId(\\d+)/organization-users/:organizationUserId(\\d+)/integrations/slack/:id(\\d+)'
  ),
  organizationUserSlackIntegrationAuth: url(
    '/v1/organizations/:organizationId(\\d+)/organization-users/:organizationUserId(\\d+)/integrations/slack/authorize'
  ),
  organizationUserSlackIntegrationBulk: url(
    '/v1/organizations/:organizationId(\\d+)/organization-users/:organizationUserId(\\d+)/integrations/slack/bulk'
  ),
  organizationSingleSignOnSettings: url('/v1/organizations/:organizationId(\\d+)/sso-settings'),
  organizationSingleSignOnSetting: url(
    '/v1/organizations/:organizationId(\\d+)/sso-settings/:organizationSsoSettingsId(\\d+)'
  ),
  organizationSingleSignOnUsers: url('/v1/organizations/:organizationId(\\d+)/sso-users'),
  organizationSingleSignOnUsersUpsert: url('/v1/organizations/:organizationId(\\d+)/sso-users/upsert'),
  organizationSingleSignOnUser: url('/v1/organizations/:organizationId(\\d+)/sso-users/:organizationSsoUserId(\\d+)'),

  organizationResources: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/resources/groups/:groupId(\\d+)/bulk'
  ),
  externalIntegrations: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/external-integrations'
  ),
  externalIntegration: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/external-integrations/:externalIntegrationId(\\d+)'
  ),
  externalIntegrationProjectSync: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/external-integrations/:externalIntegrationId(\\d+)/project-sync'
  ),
  externalIntegrationProjectSyncErrors: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/external-integrations/:externalIntegrationId(\\d+)/project-sync-errors'
  ),
  jiraOAuthRequest: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/oauth/jira/oauth-client/authorization-request'
  ),
  jiraOAuthResponse: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/oauth/jira/oauth-client/authorization-response'
  ),
  jiraOauthAccounts: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/jira/oauth-accounts'),
  jiraExternalIntegrations: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/external-integrations/jira'
  ),
  jiraExternalIntegration: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/external-integrations/jira/:externalIntegrationId(\\d+)'
  ),
  jiraExternalIntegrationConnectionInformation: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/external-integrations/jira/:externalIntegrationId(\\d+)/connection-information'
  ),
  jiraAccessibleResources: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/jira/accessible-resources'
  ),
  jiraAdvancedFiltersFields: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/jira/suggestions/reference'
  ),
  jiraProjects: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/jira/projects'),
  jiraProjectDetails: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/jira/projects/:projectId(\\d+)/details'
  ),
  jiraProjectIssuesCount: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/jira/issues/count'
  ),
  jiraProjectUserCount: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/jira/users/count-pending'
  ),
  jiraProjectMappingFieldsWithIssueTypes: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/jira/mapping-fields/with-issue-types'
  ),
  jiraPushItems: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/jira/issues'),
  jiraSuggestions: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/jira/suggestions'),
  jiraTimesheetsAuthentication: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/jira/timesheets/authenticate'
  ),
  iterations: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/iterations'),
  iteration: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/iterations/:iterationId(\\d+)'),
  iterationMetrics: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/iterations/:iterationId(\\d+)/metrics'
  ),
  iterationClearTasks: url(
    '/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/iterations/:iterationId(\\d+)/clear-tasks'
  ),
  novaPlans: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/nova/plans'),
  novaPlan: url('/v1/organizations/:organizationId(\\d+)/workspaces/:workspaceId(\\d+)/nova/plans/:planId(\\d+)'),
};

export const thirdPartyUrls = {
  productAdvisor: 'https://www.liquidplanner.com/scheduling-a-demo-with-liquidplanner/',
  billingFAQ: 'https://www.liquidplanner.com/billing-faq/',
  release_notes_url: 'https://www.liquidplanner.com/blog/category/release-notes/',
  contactSupport: 'https://www.liquidplanner.com/contact/?your-subject=Support+for+the+New+LiquidPlanner',
  tempoIo: 'https://www.tempo.io/',
};
