import { ItemType } from 'daos/enums';
import { CustomFieldFilterWithRequiredId, LocationFilterProjectSettings, LocationFilterTaskSettings } from 'daos/types';

export enum CustomItemFilterLocation {
  AssignmentGrid = 'assignmentGrid',
  DataGrid = 'dataGrid',
  Portfolio = 'portfolio',
  Project = 'project',
  TaskBoard = 'taskBoard',
  TimesheetExport = 'timesheetExport',
  Workload = 'workload',
  Iteration = 'iteration',
}

export type CustomPropertiesSelectedValue = number | boolean | string;

// In this case, item TYPE is the most fitting description
// eslint-disable-next-line @typescript-eslint/naming-convention
export type CustomItemFilterItemType = ItemType.PROJECTS | ItemType.TASKS;

export interface OtherCustomProperties {
  asap: boolean;
  atRisk: boolean;
  workLimitRisk: boolean;
  nameContains?: string;
  hasFiles: boolean;
  createdByUserId?: number;
  customFieldsAndValues?: ReadonlyArray<CustomFieldFilterWithRequiredId>;
}

export type CustomTaskFilters = Partial<LocationFilterTaskSettings>;

export type CustomProjectFilters = Partial<LocationFilterProjectSettings>;
