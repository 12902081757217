import { useSelector } from 'react-redux';

import { ItemType } from 'daos/enums';
import { getColumnSelectionListFromColumnOptionsRefactor } from 'features/common/selection_list/index';
import { getFieldsById } from 'redux/entities/selectors/custom_field';

import {
  getPackageItemAndAllFieldColumns,
  getPackageItemColumns,
  getProjectItemAndAllFieldColumns,
  getProjectItemColumns,
  getSubfolderItemAndAllFieldColumns,
  getSubfolderItemColumns,
  getTaskItemAndAllFieldColumns,
  getTaskItemColumns,
  getAssignmentItemColumns,
} from './selectors';

/** Item columns with ACTIVE custom fields (no notes) */
export const useAssignmentColumnSelectionList = ({ forPPPGrid = false }: { forPPPGrid?: boolean }) => {
  const customFields = useSelector(getFieldsById);
  const assignmentColumns = useSelector((state) => getAssignmentItemColumns(state, forPPPGrid));

  return getColumnSelectionListFromColumnOptionsRefactor({
    columnOptions: assignmentColumns,
    customFields,
  });
};

export const usePackageColumnAndActiveFieldSelectionList = ({ forPPPGrid = false }: { forPPPGrid?: boolean }) => {
  const packageColumns = useSelector((state) => getPackageItemColumns(state, forPPPGrid));
  return useColumnsAndActiveFieldSelectionList(packageColumns);
};

export const useProjectSubFolderColumnAndActiveFieldSelectionList = ({
  forPPPGrid = false,
}: {
  forPPPGrid?: boolean;
}) => {
  const subfolderColumns = useSelector((state) => getSubfolderItemColumns(state, forPPPGrid));
  return useColumnsAndActiveFieldSelectionList(subfolderColumns);
};

export const useProjectColumnAndActiveFieldSelectionList = ({ forPPPGrid = false }: { forPPPGrid?: boolean }) => {
  const projectColumns = useSelector((state) => getProjectItemColumns(state, forPPPGrid));
  return useColumnsAndActiveFieldSelectionList(projectColumns);
};

export const useTaskColumnAndActiveFieldSelectionList = ({ forPPPGrid = false }: { forPPPGrid?: boolean }) => {
  const taskColumns = useSelector((state) => getTaskItemColumns(state, forPPPGrid));
  return useColumnsAndActiveFieldSelectionList(taskColumns);
};

const useColumnsAndActiveFieldSelectionList = (columns: ReadonlyArray<string>) => {
  const customFields = useSelector(getFieldsById);

  return getColumnSelectionListFromColumnOptionsRefactor({
    columnOptions: columns,
    customFields,
  });
};

export const useColumnsAndActiveFieldsByItemType = ({ forPPPGrid = false }: { forPPPGrid?: boolean }) => ({
  [ItemType.WORKSPACE_ROOTS]: [],
  [ItemType.PACKAGES]: usePackageColumnAndActiveFieldSelectionList({ forPPPGrid }),
  [ItemType.PROJECTS]: useProjectColumnAndActiveFieldSelectionList({ forPPPGrid }),
  [ItemType.FOLDERS]: useProjectSubFolderColumnAndActiveFieldSelectionList({ forPPPGrid }),
  [ItemType.TASKS]: useTaskColumnAndActiveFieldSelectionList({ forPPPGrid }),
  [ItemType.ASSIGNMENTS]: useAssignmentColumnSelectionList({ forPPPGrid }),
});

/** Item columns with ALL custom fields (active and archived, but no notes) */
const usePackageColumnAndAllFieldSelectionList = () => {
  const customFields = useSelector(getFieldsById);
  const packageColumns = useSelector(getPackageItemAndAllFieldColumns);

  return getColumnSelectionListFromColumnOptionsRefactor({
    columnOptions: packageColumns,
    customFields,
  });
};

const useProjectSubFolderColumnAndAllFieldSelectionList = () => {
  const customFields = useSelector(getFieldsById);
  const subfolderColumns = useSelector(getSubfolderItemAndAllFieldColumns);

  return getColumnSelectionListFromColumnOptionsRefactor({
    columnOptions: subfolderColumns,
    customFields,
  });
};

const useProjectColumnAndAllFieldSelectionList = () => {
  const customFields = useSelector(getFieldsById);
  const projectColumns = useSelector(getProjectItemAndAllFieldColumns);

  return getColumnSelectionListFromColumnOptionsRefactor({
    columnOptions: projectColumns,
    customFields,
  });
};

const useTaskColumnAndAllFieldSelectionList = () => {
  const customFields = useSelector(getFieldsById);
  const taskColumns = useSelector(getTaskItemAndAllFieldColumns);

  return getColumnSelectionListFromColumnOptionsRefactor({
    columnOptions: taskColumns,
    customFields,
  });
};

export const useColumnsAndAllFieldsByItemType = () => ({
  [ItemType.WORKSPACE_ROOTS]: [],
  [ItemType.PACKAGES]: usePackageColumnAndAllFieldSelectionList(),
  [ItemType.PROJECTS]: useProjectColumnAndAllFieldSelectionList(),
  [ItemType.FOLDERS]: useProjectSubFolderColumnAndAllFieldSelectionList(),
  [ItemType.TASKS]: useTaskColumnAndAllFieldSelectionList(),
  [ItemType.ASSIGNMENTS]: useAssignmentColumnSelectionList({ forPPPGrid: false }),
});
