import { capitalize } from 'lodash';
import { Column, FormatterProps, SummaryFormatterProps } from 'react-data-grid';

import { customColumnDefinitions, defaultWidths } from 'containers/shared/custom_column';
import { CustomFieldNameForGuests } from 'containers/shared/custom_field_name';
import { ItemType, ListWidgetType, UserType, WidgetType } from 'daos/enums';
import { WidgetWithData } from 'daos/model_types';
import { WidgetConfigColumn } from 'daos/widget_column';
import { itemListWidgetSelectColumn } from 'features/common/data_grid/column_definitions/select';
import { selectPeopleRowByUserTypeColumn } from 'features/common/data_grid/column_definitions/select_all_column_definition';
import { getFormattedDisplay } from 'features/common/data_grid/safe_widgets/formatter_helpers';
import { getCellClass, getColumnKey } from 'features/common/data_grid/safe_widgets/helpers';
import { EntityRow } from 'features/common/data_grid/types';
import { SelectionDropdownOptions } from 'features/common/inputs/dropdowns/selection_dropdown/enums';
import { getItemTypeForListWidgetType } from 'features/dashboards/widget_click_through/grids/helpers';
import {
  ItemNameLink,
  getItemPanelAwareItemLinkToURL,
} from 'features/dashboards_v2/widget/widgets/list/table/item_name_cell';
import { PeopleGridUsernameLink } from 'features/dashboards_v2/widget/widgets/list/table/user_name_cell';
import { GridSummaryRow, ItemRow, PeopleRow } from 'features/dashboards_v2/widget/widgets/reports/types';
import { TabNames } from 'features/item_panel/sections/tab_names';
import { COLUMN_WIDTH_XL, TEXT_ALIGN_LEFT } from 'lib/constants';

const SafeWidgetPeopleGridFormatter = (
  props: FormatterProps<EntityRow<PeopleRow, GridSummaryRow>, GridSummaryRow> & {
    widget: WidgetWithData;
  }
) => {
  const { column, row, widget } = props;
  const columnKey = column.key;

  if (columnKey === 'user') {
    return <PeopleGridUsernameLink userData={row.user} widget={widget} />;
  }

  const display = getFormattedDisplay(columnKey, row.columnDataMap);

  return <>{display}</>;
};

const SafeWidgetItemGridFormatter = (
  props: FormatterProps<EntityRow<ItemRow, GridSummaryRow>, GridSummaryRow> & {
    widget: WidgetWithData;
  }
) => {
  const { column, row, widget } = props;
  const columnKey = column.key;

  if (columnKey === 'itemName') {
    const nameColumnToURL = getItemPanelAwareItemLinkToURL({
      itemId: row.itemId,
      tabName: TabNames.Assignments,
      parentId: row.itemParentId ?? 0,
      widget,
    });

    return <ItemNameLink widget={widget} itemRow={row} customToURL={nameColumnToURL} />;
  }

  const displayCell = getFormattedDisplay(columnKey, row.columnDataMap);

  return <>{displayCell}</>;
};

const SafeWidgetGridSummaryFormatter = <T extends PeopleRow | ItemRow>(
  props: SummaryFormatterProps<GridSummaryRow, T>
) => {
  const { column, row } = props;
  const columnKey = column.key;
  const isSummaryRow = true;

  const display = getFormattedDisplay(columnKey, row.columnDataMap, isSummaryRow);

  return <>{display}</>;
};

const createItemColumnDefinition = ({
  configColumn,
  dateRangeDisplay,
  widget,
}: {
  configColumn: WidgetConfigColumn;
  dateRangeDisplay: string;
  widget: WidgetWithData;
}) => {
  return {
    name: <CustomFieldNameForGuests configColumn={configColumn} dateRangeDisplay={dateRangeDisplay} />,
    key: getColumnKey(configColumn),
    formatter: (props: FormatterProps<EntityRow<ItemRow, GridSummaryRow>, GridSummaryRow>) => (
      <SafeWidgetItemGridFormatter {...props} widget={widget} />
    ),
    summaryFormatter: SafeWidgetGridSummaryFormatter,
    resizable: true,
    sortable: customColumnDefinitions[configColumn.column]?.sortable ?? true,
    headerCellClass: TEXT_ALIGN_LEFT,
    cellClass: getCellClass(configColumn),
    width: customColumnDefinitions[configColumn.column]?.widths.grid ?? defaultWidths.grid,
    summaryCellClass: 'grid-total-summary',
  };
};

const createPeopleColumnDefinition = ({
  configColumn,
  widget,
}: {
  configColumn: WidgetConfigColumn;
  widget: WidgetWithData;
}) => {
  const dateRangeDisplay = '';

  return {
    name: <CustomFieldNameForGuests configColumn={configColumn} dateRangeDisplay={dateRangeDisplay} />,
    key: getColumnKey(configColumn),
    formatter: (props: FormatterProps<EntityRow<PeopleRow, GridSummaryRow>, GridSummaryRow>) => (
      <SafeWidgetPeopleGridFormatter {...props} widget={widget} />
    ),
    summaryFormatter: SafeWidgetGridSummaryFormatter,
    resizable: true,
    sortable: true,
    headerCellClass: TEXT_ALIGN_LEFT,
    cellClass: getCellClass(configColumn),
    width: customColumnDefinitions[configColumn.column]?.widths.grid ?? defaultWidths.grid,
    summaryCellClass: 'grid-total-summary',
  };
};

const getItemNameColumnDefinition = (widget: WidgetWithData) => {
  const name = capitalize(widget.config.listType ?? 'Items');

  return {
    name,
    key: 'itemName',
    formatter: (props: FormatterProps<EntityRow<ItemRow, GridSummaryRow>, GridSummaryRow>) => (
      <SafeWidgetItemGridFormatter {...props} widget={widget} />
    ),
    summaryFormatter: () => {
      return <strong>Totals</strong>;
    },
    sortable: false,
    resizable: true,
    width: COLUMN_WIDTH_XL,
    headerCellClass: TEXT_ALIGN_LEFT,
    summaryCellClass: 'grid-total-summary',
  };
};

const getUsernameColumnDefinition = (widget: WidgetWithData) => {
  const isPeopleListWidget = getIsPeopleListWidget(widget);
  return {
    name: 'Username',
    key: 'user',
    formatter: (props: FormatterProps<EntityRow<PeopleRow, GridSummaryRow>, GridSummaryRow>) => (
      <SafeWidgetPeopleGridFormatter {...props} widget={widget} />
    ),
    summaryFormatter: () => {
      return <strong>Totals</strong>;
    },
    frozen: isPeopleListWidget,
    sortable: false,
    resizable: true,
    width: COLUMN_WIDTH_XL,
    headerCellClass: TEXT_ALIGN_LEFT,
    summaryCellClass: 'grid-total-summary',
  };
};

const getSelectItemsColumnDefinition = (
  onBulkSelect: (selection: SelectionDropdownOptions) => void,
  itemType: ItemType,
  rowData: ReadonlyArray<ItemRow>
) => {
  return itemListWidgetSelectColumn(onBulkSelect, itemType, rowData);
};

const getSelectPeopleColumnDefinition = (
  onSelect: (selection: SelectionDropdownOptions) => void,
  selectedUserType: UserType | undefined,
  hasAccess: boolean
) => {
  return selectPeopleRowByUserTypeColumn(onSelect, hasAccess, selectedUserType);
};

export const getSafeWidgetItemGridColumns = (
  widget: WidgetWithData,
  dateRangeDisplay = '',
  configColumn: ReadonlyArray<WidgetConfigColumn>,
  selectColumnProps:
    | { onBulkSelect: (selection: SelectionDropdownOptions) => void; rowData: ReadonlyArray<ItemRow> }
    | undefined
) => {
  const columnDefinitions: Array<Column<EntityRow<ItemRow, GridSummaryRow>, GridSummaryRow>> = [];

  const isItemListWidget = getIsItemListWidget(widget);

  if (isItemListWidget && selectColumnProps) {
    const { onBulkSelect, rowData } = selectColumnProps;
    const itemType = getItemTypeForListWidgetType(widget.config.listType);
    const selectColumn = getSelectItemsColumnDefinition(onBulkSelect, itemType, rowData);

    columnDefinitions.push(selectColumn);
  }

  columnDefinitions.push(getItemNameColumnDefinition(widget));

  configColumn.forEach((configColumn) => {
    const columnDef = createItemColumnDefinition({ configColumn, dateRangeDisplay, widget });

    columnDefinitions.push(columnDef);
  });

  return columnDefinitions;
};

export const getSafeWidgetGridItemRows = (rowData: ReadonlyArray<ItemRow>) => {
  return rowData.map((itemRow) => ({
    id: itemRow.itemId,
    ...itemRow,
    numberOfRows: 0,
  }));
};

export const getSafeWidgetGridPeopleRows = (rowData: ReadonlyArray<PeopleRow>) => {
  return rowData.map((peopleRow) => ({
    id: peopleRow.user.userId,
    ...peopleRow,
    numberOfRows: 0,
  }));
};

export const getSafeWidgetPeopleGridColumns = (
  widget: WidgetWithData,
  configColumns: ReadonlyArray<WidgetConfigColumn>,
  selectColumnProps: {
    hasAccess: boolean;
    onSelect: (selection: SelectionDropdownOptions) => void;
    selectedUserType: UserType | undefined;
    showSelectColumn: boolean;
  }
) => {
  const columnDefinitions: Array<Column<EntityRow<PeopleRow, GridSummaryRow>, GridSummaryRow>> = [];

  const isPeopleListWidget = getIsPeopleListWidget(widget);

  if (isPeopleListWidget && selectColumnProps.showSelectColumn) {
    const { hasAccess, onSelect, selectedUserType } = selectColumnProps;
    const selectColumn = getSelectPeopleColumnDefinition(onSelect, selectedUserType, hasAccess);

    columnDefinitions.push(selectColumn);
  }

  columnDefinitions.push(getUsernameColumnDefinition(widget));

  configColumns.forEach((configColumn) => {
    const columnDef = createPeopleColumnDefinition({ configColumn, widget });

    columnDefinitions.push(columnDef);
  });

  return columnDefinitions;
};

function getIsPeopleListWidget(widget: WidgetWithData) {
  const { config, widgetType } = widget;
  const isListWidget = widgetType === WidgetType.List;
  const isUserList = config.listType === ListWidgetType.Users;

  return isListWidget && isUserList;
}

function getIsItemListWidget(widget: WidgetWithData) {
  const { config, widgetType } = widget;
  const isListWidget = widgetType === WidgetType.List;

  const isItemList =
    config.listType === ListWidgetType.Assignment ||
    config.listType === ListWidgetType.Tasks ||
    config.listType === ListWidgetType.Folders ||
    config.listType === ListWidgetType.Projects ||
    config.listType === ListWidgetType.Packages;

  return isListWidget && isItemList;
}
