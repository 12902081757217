import { LpFormikCheckbox } from 'features/common/forms/formik/checkbox';
import { WidgetSettingsField } from 'features/dashboards/widgets/widget_settings';

export const ShowWidgetTotals = () => (
  <WidgetSettingsField name="">
    <LpFormikCheckbox
      data-testid="showTotals"
      name={'showTotals'}
      label={<label className="total-row__checkbox">Show Totals</label>}
    />
  </WidgetSettingsField>
);
