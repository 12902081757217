import moment from 'moment-timezone';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Label } from 'semantic-ui-react';

import NewItemLabel from 'containers/shared/notice_icons/new_item';
import { PlanFamily, TrialStatus } from 'daos/enums';
import { upgradedPlanFamilies } from 'daos/plan';
import { LpMenuItemAsLink } from 'features/common/as_components';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { getInAppNotificationsForWorkspaceUserId } from 'features/common/in_app_notifications/selectors';
import {
  grid2Regular,
  LpIcon,
  cogRegular as administrationIconRegular,
  folderClosedRegular,
  giftRegular,
  graduationCapRegular,
  messagesQuoteRegular,
  passportRegular,
  squareUserRegular,
  starRegular,
  usersRegular,
  calendarArrowUpRegular,
} from 'features/common/lp_icon';
import { reportLeftMenuSegmentOriginAction } from 'features/organization/left_nav/helpers';
import { LeftNavMenu } from 'features/organization/left_nav/menu';
import { LeftNavMenuItemPopup } from 'features/organization/left_nav/popup';
import { useHasFeature } from 'hooks/use_has_feature';
import useOrganizationFetchOnOrganizationModified from 'hooks/use_organization_fetch_on_organization_modified';
import { useWorkspaceFetchOnOrganizationModified } from 'hooks/use_workspace_fetch_on_organization_modified';
import { useFetchWorkspaceUserCountsOnOrganizationModified } from 'hooks/use_workspace_user_count_fetch_on_organization_modified';
import { OriginAction } from 'lib/avo/client';
import { FeatureFlag } from 'lib/feature_flags';
import { pluralize } from 'lib/helpers';
import { frontend } from 'lib/urls';
import { getCurrentUserDashboardGuestCount } from 'redux/entities/selectors/dashboard_guest';
import { getCurrentOrganization } from 'redux/entities/selectors/organization';
import { getCurrentOrganizationPlan } from 'redux/entities/selectors/plan';
import { getCurrentWorkspaceUserId } from 'redux/entities/selectors/user';

import { WorkspaceLeftNavMenuUrlKeys } from './enums';
import { conditionallyAddTab, isAdministrationTabActive, workspaceLeftNavPaths } from './helpers';
import { useActiveTabForLeftNav } from './hooks/use_active_tab_for_left_nav';
import { NavigationMenuDisplay } from './navigation_menu_display';
import { LeftNavMenuItems } from './types';

import './left-nav-menu.scss';

export const WorkspaceMenu = ({ navExpanded }: { navExpanded: boolean }) => {
  const pathname = useLocation().pathname;
  const organizationId = useSelector(getCurrentOrganizationId);
  const organization = useSelector(getCurrentOrganization);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const currentPlanFamily = useSelector(getCurrentOrganizationPlan)?.family;
  const premiumFeaturesTrialStatus = organization?.flags.premiumFeaturesTrial;
  const passportCount = useSelector(getCurrentUserDashboardGuestCount);
  const hasPassports = passportCount > 0;
  const currentWorkspaceUserId = useSelector(getCurrentWorkspaceUserId);
  const inAppNotifications = useSelector((state) =>
    getInAppNotificationsForWorkspaceUserId(state, currentWorkspaceUserId)
  );

  useOrganizationFetchOnOrganizationModified(true);
  useWorkspaceFetchOnOrganizationModified();

  const currentTimeUTC = moment().utc();
  const trialEndUTC = moment(organization?.flags.trialEnd ?? currentTimeUTC);
  const dateDifference = trialEndUTC.diff(currentTimeUTC, 'days');
  const dateRangeDifference = Math.max(dateDifference, 0);

  const dateRangeDifferenceLabel = pluralize('day', dateRangeDifference);

  const isInUltimateTrial = currentPlanFamily === PlanFamily.UltimateTrial;
  const isPremiumFeaturesTrialInProgress = premiumFeaturesTrialStatus === TrialStatus.InProgress;
  const includePremiumFeaturesTrial =
    currentPlanFamily === PlanFamily.Free && premiumFeaturesTrialStatus !== TrialStatus.NotSupported;
  const premiumFeaturesTrialExpired = premiumFeaturesTrialStatus === TrialStatus.AvailableContactSupport;

  const { workspaceUserCounts } = useFetchWorkspaceUserCountsOnOrganizationModified();

  const { getActiveTabForLeftNav } = useActiveTabForLeftNav();

  const isIterationsFeature = useHasFeature(FeatureFlag.iterationBucket);

  const leftNavMenuItems = useMemo(() => {
    const menuItems: Array<LeftNavMenuItems> = [
      ...conditionallyAddTab(
        {
          key: WorkspaceLeftNavMenuUrlKeys.DashboardPassport,
          label: `Dashboard Passports (${passportCount})`,
          icon: passportRegular,
          to: frontend.dashboardPassports.url({}),
          segmentOriginAction: OriginAction.CLICKED_ON_DASHBOARD_PASSPORT,
        },
        hasPassports
      ),
      {
        key: WorkspaceLeftNavMenuUrlKeys.Academy,
        label: NavigationMenuDisplay.AcademyHome,
        icon: graduationCapRegular,
        to: frontend.academy.url({ organizationId, workspaceId }),
        segmentOriginAction: OriginAction.CLICKED_ON_ACADEMY,
      },
      ...conditionallyAddTab(
        {
          key: WorkspaceLeftNavMenuUrlKeys.Iterations,
          label: NavigationMenuDisplay.Iterations,
          icon: calendarArrowUpRegular,
          to: frontend.iterationsPage.url({ organizationId, workspaceId }),
          segmentOriginAction: OriginAction.CLICKED_ON_ITERATIONS_LINK,
        },
        isIterationsFeature
      ),
      {
        key: WorkspaceLeftNavMenuUrlKeys.Projects,
        label: NavigationMenuDisplay.PortfolioHome,
        icon: folderClosedRegular,
        to: frontend.scheduledCollectionPortfolio.url({ organizationId, workspaceId }),
        path: workspaceLeftNavPaths[WorkspaceLeftNavMenuUrlKeys.Projects],
        segmentOriginAction: OriginAction.CLICKED_ON_PROJECTS,
      },
      {
        className: 'people',
        key: WorkspaceLeftNavMenuUrlKeys.People,
        label: (
          <>
            {NavigationMenuDisplay.People}
            {workspaceUserCounts.member === 1 && navExpanded && <Label size="mini" content="+" />}
          </>
        ),

        icon: usersRegular,
        to: frontend.people.url({ organizationId, workspaceId }),
        segmentOriginAction: OriginAction.CLICKED_ON_PEOPLE,
      },
      {
        key: WorkspaceLeftNavMenuUrlKeys.Library,
        label: NavigationMenuDisplay.Library,
        icon: grid2Regular,
        to: frontend.workspaceLibrary.url({ organizationId, workspaceId }),
        segmentOriginAction: OriginAction.CLICKED_ON_LIBRARY,
      },
      {
        className: 'administration',
        key: WorkspaceLeftNavMenuUrlKeys.Administration,
        label: (
          <>
            {NavigationMenuDisplay.Administration}
            {currentPlanFamily && !upgradedPlanFamilies.has(currentPlanFamily) && navExpanded && (
              <Label size="mini" content="BUY" />
            )}
          </>
        ),
        icon: administrationIconRegular,
        path: isAdministrationTabActive(pathname)
          ? workspaceLeftNavPaths[WorkspaceLeftNavMenuUrlKeys.Administration]
          : [],
        to: frontend.workspaceHub.url({ organizationId, workspaceId }),
        segmentOriginAction: OriginAction.CLICKED_ON_ADMINISTRATION,
      },
      {
        key: WorkspaceLeftNavMenuUrlKeys.MyWork,
        label: NavigationMenuDisplay.MyWork,
        icon: squareUserRegular,
        to: frontend.myWork.url({ organizationId, workspaceId }),
        segmentOriginAction: OriginAction.CLICKED_ON_MY_WORK,
      },
      {
        key: WorkspaceLeftNavMenuUrlKeys.MyDiscussions,
        label: 'My Discussions',
        icon: messagesQuoteRegular,
        to: frontend.myDiscussions.url({ organizationId, workspaceId }),
        needsAttention: inAppNotifications?.unreadDiscussionPosts ?? false,
        segmentOriginAction: OriginAction.CLICKED_ON_MY_DISCUSSIONS,
      },
      {
        key: WorkspaceLeftNavMenuUrlKeys.Favorites,
        label: NavigationMenuDisplay.Favorites,
        icon: starRegular,
        to: frontend.favorites.url({ organizationId, workspaceId }),
        segmentOriginAction: OriginAction.CLICKED_ON_MY_FAVORITES,
      },
    ];

    if (includePremiumFeaturesTrial || isPremiumFeaturesTrialInProgress) {
      const inProgressContent = (
        <>
          {dateRangeDifference} {dateRangeDifferenceLabel}
        </>
      );
      const ctaContent = 'TRY';

      const premiumTrialLabelContent = () => {
        if (isPremiumFeaturesTrialInProgress) {
          return inProgressContent;
        }

        return ctaContent;
      };

      menuItems.unshift({
        className: 'premium-features-trial',
        key: WorkspaceLeftNavMenuUrlKeys.PremiumFeaturesTrial,
        label: (
          <>
            {NavigationMenuDisplay.PremiumFeaturesTrial}
            {!premiumFeaturesTrialExpired && navExpanded ? (
              <Label size="mini" content={premiumTrialLabelContent()} />
            ) : null}
          </>
        ),
        icon: giftRegular,
        to: frontend.organizationHubUpgrade.url({ organizationId }),
        segmentOriginAction: OriginAction.CLICKED_ON_PREMIUM_FEATURES_TRIAL,
      });
    }

    if (isInUltimateTrial) {
      menuItems.unshift({
        className: 'ultimate-trial',
        key: WorkspaceLeftNavMenuUrlKeys.UltimateTrial,
        label: (
          <>
            {NavigationMenuDisplay.UltimateTrial}
            <Label
              size="mini"
              content={
                <>
                  {dateRangeDifference} {dateRangeDifferenceLabel}
                </>
              }
              className="ultimate-trial-label"
            />
          </>
        ),
        icon: giftRegular,
        to: frontend.organizationHubUpgrade.url({ organizationId }),
        segmentOriginAction: OriginAction.CLICKED_ON_ULTIMATE_TRIAL,
      });
    }

    return menuItems.map(({ segmentOriginAction, className, key, label, icon, path, to, needsAttention }) => {
      const leftNavMenuItem = (
        <LpMenuItemAsLink
          className={className}
          key={key}
          active={getActiveTabForLeftNav(path, key)}
          to={to}
          onClick={() =>
            reportLeftMenuSegmentOriginAction({
              originAction: segmentOriginAction,
              workspaceId: workspaceId,
              pathName: pathname,
            })
          }
        >
          <LpIcon className="icon" icon={icon} size={navExpanded ? 'lg' : '2x'} color="white" />
          {navExpanded && <span className="menu-label">{label}</span>}
          {needsAttention && <NewItemLabel />}
        </LpMenuItemAsLink>
      );

      return navExpanded ? (
        leftNavMenuItem
      ) : (
        <LeftNavMenuItemPopup key={key} label={label} menuItem={leftNavMenuItem} />
      );
    });
  }, [
    passportCount,
    hasPassports,
    organizationId,
    workspaceId,
    isIterationsFeature,
    workspaceUserCounts.member,
    navExpanded,
    currentPlanFamily,
    pathname,
    inAppNotifications?.unreadDiscussionPosts,
    includePremiumFeaturesTrial,
    isPremiumFeaturesTrialInProgress,
    isInUltimateTrial,
    dateRangeDifference,
    dateRangeDifferenceLabel,
    premiumFeaturesTrialExpired,
    getActiveTabForLeftNav,
  ]);

  return <LeftNavMenu navExpanded={navExpanded}>{leftNavMenuItems}</LeftNavMenu>;
};
