import { SyntheticEvent } from 'react';
import { DropdownProps, Dropdown } from 'semantic-ui-react';

import { ScheduleDirective, ScheduleDirectiveToScheduleDirectiveDisplayText } from 'daos/enums';
import { Iteration } from 'daos/model_types';
import { lpDropdownSearch } from 'features/common/inputs/dropdowns/helpers';
import { PortalDropdown } from 'features/common/inputs/dropdowns/portal_dropdown';

import {
  getIterationPriorityDropdownOptions,
  getScheduleDirectiveDropdownOption,
  getSelectedIterationDropdownText,
} from './options';
import './schedule_directive_dropdown.scss';

interface ScheduleDirectiveDropdownProps {
  disabled?: DropdownProps['disabled'];
  onChange?: DropdownProps['onChange'];
  scheduleDirective: ScheduleDirective;
  onClose?: () => void;
  isOpen?: boolean;
  hasError?: boolean;
  usePortal?: boolean;
}

interface IterationPriorityDropdownProps {
  disabled?: DropdownProps['disabled'];
  hasError?: boolean;
  isOpen?: boolean;
  iterations: ReadonlyArray<Iteration>;
  selectedIterationId: number | null;
  onChange: (props: { scheduleDirective: ScheduleDirective; iterationId: number | null }) => void;
  onClose?: () => void;
  scheduleDirective: ScheduleDirective;
  usePortal?: boolean;
}

export const scheduleDirectiveOptions = [
  ScheduleDirective.NORMAL,
  ScheduleDirective.ASAP_IN_PROJECT,
  ScheduleDirective.ASAP_IN_PACKAGE,
  ScheduleDirective.ASAP_IN_WORKSPACE,
].map(getScheduleDirectiveDropdownOption);

export const ScheduleDirectiveDropdown = ({
  disabled,
  onChange,
  scheduleDirective,
  onClose,
  isOpen,
  usePortal = false,
  hasError = false,
}: ScheduleDirectiveDropdownProps) => {
  const handleChange = (_: SyntheticEvent<HTMLElement>, { value }: DropdownProps) => {
    if (scheduleDirective !== value) {
      if (onChange) {
        onChange(_, { value });
      }
    }
  };

  const scheduleDirectiveDropdown = (
    <Dropdown
      disabled={disabled}
      onClose={onClose}
      error={hasError}
      text={ScheduleDirectiveToScheduleDirectiveDisplayText[scheduleDirective]}
      className="lp-schedule-directive-dropdown"
      onChange={handleChange}
      options={scheduleDirectiveOptions}
      selection
      selectOnNavigation={false}
      value={scheduleDirective}
      open={isOpen}
    />
  );

  return usePortal ? <PortalDropdown>{scheduleDirectiveDropdown}</PortalDropdown> : scheduleDirectiveDropdown;
};

export const IterationPriorityDropdown = ({
  disabled,
  hasError,
  isOpen,
  iterations,
  onChange,
  onClose,
  scheduleDirective,
  selectedIterationId,
  usePortal,
}: IterationPriorityDropdownProps) => {
  const handleChange = (_: SyntheticEvent<HTMLElement>, { value }: DropdownProps) => {
    const valueAsNumber = Number(value);
    const isIterationValue = !isNaN(valueAsNumber);
    if (isIterationValue) {
      onChange({ scheduleDirective: ScheduleDirective.NORMAL, iterationId: valueAsNumber });
    } else {
      onChange({ scheduleDirective: value as ScheduleDirective, iterationId: null });
    }
  };

  const activeIterations = iterations.filter((iteration) => !iteration.done || iteration.id == selectedIterationId);
  const allOptions = getIterationPriorityDropdownOptions(selectedIterationId, activeIterations);
  const text = getSelectedIterationDropdownText({ iterations, selectedIterationId, scheduleDirective });

  const dropdown = (
    <Dropdown
      className="lp-schedule-directive-dropdown lp-schedule-directive-dropdown--iterations"
      disabled={disabled}
      error={hasError}
      onChange={handleChange}
      onClose={onClose}
      open={isOpen}
      options={allOptions}
      search={lpDropdownSearch}
      selection
      selectOnNavigation={false}
      text={text}
      value={selectedIterationId ?? scheduleDirective}
    />
  );

  return usePortal ? <PortalDropdown>{dropdown}</PortalDropdown> : dropdown;
};
