export const AssignmentBar = ({
  isAssignmentPlaceholder,
  isAssignmentTrackingOnly,
  isDone,
  isAssignmentNeedsAttention,
  isParentEffectivelyOnHold,
  isParentOnHold,
  isScheduled,
  assignedToDoneIteration,
}: {
  isAssignmentPlaceholder: boolean;
  isAssignmentTrackingOnly: boolean;
  isDone: boolean;
  isAssignmentNeedsAttention: boolean;
  isParentEffectivelyOnHold: boolean;
  isParentOnHold: boolean;
  isScheduled: boolean;
  assignedToDoneIteration: boolean;
}) => {
  if (!isDone) {
    if (assignedToDoneIteration) {
      return <span className="assignment-on-hold">Task Not Scheduled (Iteration Done)</span>;
    }
    if (isParentOnHold || isParentEffectivelyOnHold) {
      return <span className="assignment-on-hold">Task On Hold {isParentEffectivelyOnHold && '(Inherited)'}</span>;
    }
  }

  if (isAssignmentTrackingOnly) {
    return <span className="assignment-tracking-only">Tracking Only</span>;
  }

  if (isAssignmentPlaceholder) {
    return <span className="assignment-placeholder">Placeholder Assignment</span>;
  }

  if (isAssignmentNeedsAttention) {
    return <span className="assignment-needs-attention">Needs Attention</span>;
  }

  if (isScheduled) {
    return (
      <div className="lp-schedule__bar-element lp-schedule__bar-target-line lp-schedule__bar-target-line--assignment" />
    );
  }

  return null;
};
