import { useMemo } from 'react';

import { StandardColumns } from 'containers/shared/custom_column/enum';
import { ScheduleSummaryItem, ScheduleSummaryItemMetrics } from 'features/common/schedule_bar/types';
import { ItemRow } from 'features/dashboards_v2/widget/widgets/reports/types';
import { calcItemsDateRange, ScheduleViewType } from 'hooks/use_items_date_range';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';
import { DateRange } from 'redux/entities/selectors/item';

export const useWidgetDateRange = (itemListRowData: ReadonlyArray<ItemRow>) => {
  const { currentBrowserDateTime } = useLocalizedFormats();

  return useMemo(() => {
    const scheduleRows = itemListRowData.map(
      (row) => row.columnDataMap[StandardColumns.ScheduleBar]?.data[0]?.scheduleData
    );
    const defaultValue: { items: Array<ScheduleSummaryItem>; allItemMetrics: ScheduleSummaryItemMetrics } = {
      items: [],
      allItemMetrics: {},
    };

    const { items, allItemMetrics } =
      scheduleRows?.reduce((prev, curr) => {
        if (curr?.item) {
          prev.items.push(curr.item);
        }
        if (curr?.itemMetrics) {
          Object.assign(prev.allItemMetrics, curr.itemMetrics);
        }

        return prev;
      }, defaultValue) ?? defaultValue;

    return items.length !== 0
      ? calcItemsDateRange({
          allItemMetrics,
          localNow: currentBrowserDateTime(),
          viewType: ScheduleViewType.Grid,
          items,
        })
      : null;
  }, [currentBrowserDateTime, itemListRowData]);
};

export function getDateRangeDisplay(dateRange: DateRange, formatLocalDate: (date: string) => string) {
  const { start, finish } = dateRange;

  return `${formatLocalDate(start.toISOString())} - ${formatLocalDate(finish.toISOString())}`;
}
