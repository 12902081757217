import { ReactNode, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router';

import { User, Workspace } from 'daos/model_types';
import { UserDao } from 'daos/user';
import { LpMenuItemAsLink } from 'features/common/as_components';
import {
  getCurrentNonGuestConnectedWorkspaces,
  getCurrentOrganizationId,
  getCurrentWorkspaceId,
} from 'features/common/current/selectors';
import {
  setCurrentOrganizationId,
  setCurrentWorkspaceId,
  setCurrentWorkspaces,
  setCurrentWorkspaceUsers,
} from 'features/common/current/slice';
import { globeRegular, graduationCapRegular, IconDefinition, LpIcon, passportRegular } from 'features/common/lp_icon';
import { reportLeftMenuItemClickedToSegment } from 'features/organization/left_nav/helpers';
import { LeftNavMenu } from 'features/organization/left_nav/menu';
import { LeftNavMenuItemPopup } from 'features/organization/left_nav/popup';
import { WorkspaceLeftNavMenuUrlKeys } from 'features/workspace/enums';
import { awaitRequestFinish } from 'lib/api';
import { frontend } from 'lib/urls';
import { getCurrentUserDashboardGuestCount } from 'redux/entities/selectors/dashboard_guest';
import { mergeEntities } from 'redux/entities/slice';

interface GuestLeftNavMenuItemProps {
  className?: string;
  href?: string;
  icon: IconDefinition;
  key: string;
  label: ReactNode;
  onClick?: () => void;
  path?: string | Array<string>;
  to: string;
  name: string;
}

interface NavMenuItemProps {
  href?: string;
  icon: IconDefinition;
  key: string;
  label: string;
  onClick?: () => void;
  pattern: string | Array<string>;
  to: string;
}

const getGuestLeftNavMenuItems = ({
  guestCount,
  organizationId,
  workspaceId,
}: {
  guestCount: number;
  organizationId: number;
  workspaceId: number;
}): ReadonlyArray<NavMenuItemProps> => [
  {
    key: WorkspaceLeftNavMenuUrlKeys.DashboardPassport,
    label: `Dashboard Passports (${guestCount})`,
    icon: passportRegular,
    to: frontend.dashboardPassports.url({}),
    pattern: [frontend.dashboardPassports.pattern, frontend.dashboardGuest.pattern],
  },
  {
    key: WorkspaceLeftNavMenuUrlKeys.Academy,
    label: 'Academy',
    icon: graduationCapRegular,
    to: frontend.academy.url({ organizationId, workspaceId }),
    pattern: [frontend.academy.pattern],
  },
];

const getHybridMemberWorkspaceMenuItem = (workspace: Workspace, onClick: () => void): NavMenuItemProps => {
  const scheduledPortfolioUrl = frontend.scheduledCollection.url({
    organizationId: workspace.organization.id,
    workspaceId: workspace.id,
  });
  return {
    key: `${workspace.id}`,
    label: workspace.name,
    icon: globeRegular,
    href: scheduledPortfolioUrl,
    to: '',
    pattern: [frontend.scheduledCollection.pattern],
    onClick,
  };
};

const getHybridGuestLeftNavMenuItems = (
  baseItems: ReadonlyArray<NavMenuItemProps>,
  hybridItems: ReadonlyArray<NavMenuItemProps>
) => {
  const combinedItems = [...baseItems];
  combinedItems.splice(1, 0, ...hybridItems);

  return combinedItems;
};

const GuestLeftNavMenuItem = (props: GuestLeftNavMenuItemProps & { navExpanded: boolean }) => {
  const pathname = useLocation().pathname;
  const { name, className, href, icon, label, navExpanded, onClick, path, to } = props;
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const isActive = !!matchPath(pathname, { path });

  const handleOnClick = (navMenuItemName: string) => {
    reportLeftMenuItemClickedToSegment({ name: navMenuItemName, workspaceId, pathName: pathname });
    onClick && onClick();
  };

  const menuItem = (
    <LpMenuItemAsLink className={className} active={isActive} href={href} onClick={() => handleOnClick(name)} to={to}>
      <LpIcon className="icon" icon={icon} size={navExpanded ? 'lg' : '2x'} color="white" />
      {navExpanded && <span className="menu-label">{label}</span>}
    </LpMenuItemAsLink>
  );

  return navExpanded ? menuItem : <LeftNavMenuItemPopup menuItem={menuItem} label={label} />;
};

export const GuestLeftNavMenu = ({ navExpanded }: { navExpanded: boolean }) => {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const dashboardGuestCount = useSelector(getCurrentUserDashboardGuestCount);
  const nonGuestWorkspaces = useSelector(getCurrentNonGuestConnectedWorkspaces);

  const fetchUser = useCurrentUserFetch();

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const handleWorkspaceSwitch = (ws: Workspace) => () => {
    dispatch(setCurrentWorkspaceId(ws.id));
    dispatch(setCurrentOrganizationId(ws.organization.id));
  };

  const baseMenuItems = getGuestLeftNavMenuItems({
    guestCount: dashboardGuestCount,
    organizationId,
    workspaceId,
  });
  const workspaceMenuItems = nonGuestWorkspaces.map((ws) =>
    getHybridMemberWorkspaceMenuItem(ws, handleWorkspaceSwitch(ws))
  );
  const combinedMenuItems = getHybridGuestLeftNavMenuItems(baseMenuItems, workspaceMenuItems);

  return (
    <LeftNavMenu navExpanded={navExpanded}>
      {combinedMenuItems.map((menuItem) => (
        <GuestLeftNavMenuItem
          {...menuItem}
          key={menuItem.key}
          name={menuItem.key}
          className=""
          navExpanded={navExpanded}
          path={menuItem.pattern}
        />
      ))}
    </LeftNavMenu>
  );
};

function useCurrentUserFetch() {
  const dispatch = useDispatch();

  const fetchUser = useCallback(() => {
    const { uuid } = dispatch(
      UserDao.fetchCurrent({ include: { includeWsUsersAndWorkspaces: true }, skipReduce: true })
    );

    dispatch(
      awaitRequestFinish<User>(uuid, {
        onSuccess: ({ entities }) => {
          dispatch(setCurrentWorkspaceUsers(Object.values(entities.workspaceUsers ?? {})));
          dispatch(setCurrentWorkspaces(entities.workspaces ?? {}));
          dispatch(mergeEntities({ dashboardGuests: entities.dashboardGuests }));
        },
      })
    );
  }, [dispatch]);

  return fetchUser;
}
