export enum ScheduleBarHoverText {
  NotScheduled = 'Not Scheduled',
  NotScheduledIterationDone = 'Not Scheduled (Iteration Done)',
  NeedsAttention = 'Needs Attention',
  AtRisk = 'At Risk',
  Finish = 'Finish',
  OnHold = 'On Hold',
  EffectivelyOnHold = 'On Hold (Inherited)',
  ClearRemaining = 'Clear Remaining',
  Done = 'Done',
  NeedRemainingEstimate = 'Need remaining estimate?',
}
