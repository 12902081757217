import classNames from 'classnames';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { textEditor } from 'react-data-grid';
import { useSelector } from 'react-redux';

import { customColumnDefinitions } from 'containers/shared/custom_column';
import { StandardColumns } from 'containers/shared/custom_column/enum';
import { ItemType, ScheduleDirective, WorkType } from 'daos/enums';
import { TargetFinishType } from 'daos/item_enums';
import { Item } from 'daos/model_types';
import {
  DateInputEditor,
  PriorityRushDropdownEditor,
  StoryPointDropdownEditor,
  TargetFinishTypeDropdownEditor,
  TaskStatusDropdownEditor,
  WorkTypeDropdownEditor,
} from 'features/common/data_grid/add_edit_grid/cell_editors';
import {
  DateFormatter,
  NumberTextFormatter,
  PriorityRushFormatter,
  StoryPointFormatter,
  TargetFinishTypeFormatter,
  TaskStatusFormatter,
  WorkTypeFormatter,
} from 'features/common/data_grid/add_edit_grid/cell_formatters';
import { getFieldCellValuesByFieldId } from 'features/common/data_grid/add_edit_grid/custom_field_helpers';
import {
  checkDoneDateEditCellClass,
  checkValidDateEditCellClass,
  checkValidNameEditCellClass,
} from 'features/common/data_grid/add_edit_grid/helpers/cell_class_names_helpers';
import {
  ADD_EDIT_DROPDOWN_CLASS,
  checkIfFieldIsRequiredAndHasNoValue,
  checkIfIterationIsClosed,
  checkValidWorkLimitCellClass,
} from 'features/common/data_grid/add_edit_grid/helpers/class_name_helpers';
import {
  reorderColumnsWithScheduleDirectiveOnly,
  reorderColumnsWithSortedErrorsFirst,
} from 'features/common/data_grid/add_edit_grid/modal_edit_grid/hooks/helpers';
import { MultiFieldEditorProps } from 'features/common/data_grid/add_edit_grid/multi_field_value_modal';
import { customFieldAddEditColumn } from 'features/common/data_grid/column_definitions/custom_fields';
import {
  benchmarkEstimate,
  descriptionColumn,
  doneDateColumn,
  itemNameColumn,
  priorityRushColumn,
  targetFinishColumn,
  targetFinishTypeColumn,
  targetStartColumn,
  taskStatusColumn,
  workTypeColumn,
} from 'features/common/data_grid/column_definitions/item';
import { AddEditGridColumnKey } from 'features/common/data_grid/enums';
import { AddEditGridColumns, AddEditGridRow, PushItemsToJiraErrorByItemId } from 'features/common/data_grid/types';
import { LpSystemId } from 'features/jira_project/modal/types';
import { useHasFeature } from 'hooks/use_has_feature';
import { useUpdateStoryPointSchemeOwners } from 'hooks/use_update_story_point_scheme_owners';
import { COLUMN_WIDTH_M } from 'lib/constants';
import { FeatureFlag } from 'lib/feature_flags';
import { convertSecondsToHours } from 'lib/helpers';
import {
  getAddEditGridActiveTaskCustomFieldsSortedByPriority,
  getCustomFieldById,
  getEditGridCustomFieldValuesByItemId,
} from 'redux/entities/selectors/custom_field';
import { getIterationsForCurrentWorkspace } from 'redux/entities/selectors/iterations';
import { getCurrentOrganization } from 'redux/entities/selectors/organization';
import { getTaskStatusesById } from 'redux/entities/selectors/task_status';

export const useTaskEditColumnDefinitions = (
  isTemplateGrid: boolean,
  setMultiEditModalProps: Dispatch<SetStateAction<MultiFieldEditorProps | undefined>>,
  jiraRequiredErrorIdsByItemId?: PushItemsToJiraErrorByItemId,
  isIssuePushClosedSprintJiraError?: boolean
): AddEditGridColumns => {
  const customFields = useSelector(getAddEditGridActiveTaskCustomFieldsSortedByPriority);
  const organization = useSelector(getCurrentOrganization);
  const iterations = useSelector(getIterationsForCurrentWorkspace);
  const hasDataCustomization = !!organization?.flags.hasDataCustomization;
  const hasStoryPointsFeature = useHasFeature(FeatureFlag.storyPoints);

  useUpdateStoryPointSchemeOwners();
  const columns = useMemo(() => {
    const storyPointColumn = hasStoryPointsFeature
      ? [
          {
            key: AddEditGridColumnKey.StoryPointsId,
            name: 'Story Points',
            editor: StoryPointDropdownEditor,
            editorOptions: { editOnClick: true, commitOnOutsideClick: false },
            formatter: StoryPointFormatter,
            resizable: true,
            width: COLUMN_WIDTH_M,
          },
        ]
      : [];

    const benchmarkEstimateColumn = jiraRequiredErrorIdsByItemId
      ? [
          {
            ...benchmarkEstimate,
            editor: textEditor,
            formatter: NumberTextFormatter,
            editorOptions: { editOnClick: true, commitOnOutsideClick: false },
            cellClass: ({ id, benchmarkEstimate }: AddEditGridRow) => {
              const isRequiredAndHasNoValue = checkIfFieldIsRequiredAndHasNoValue({
                fieldValue: benchmarkEstimate,
                fieldId: LpSystemId.BenchmarkEstimate,
                jiraRequiredSystemFieldErrorIds: jiraRequiredErrorIdsByItemId[id]?.lpSystemFieldId ?? [],
              });
              return classNames(isRequiredAndHasNoValue, checkValidWorkLimitCellClass(benchmarkEstimate ?? 0));
            },
          },
        ]
      : [];

    const baseColumns = [
      {
        ...itemNameColumn(ItemType.TASKS),
        key: AddEditGridColumnKey.Name,
        cellClass: checkValidNameEditCellClass,
        editor: textEditor,
        frozen: true,
        width: customColumnDefinitions[StandardColumns.Assignment].widths.grid,
      },
      {
        ...descriptionColumn,
        key: AddEditGridColumnKey.Description,
        editor: textEditor,
        width: COLUMN_WIDTH_M,
      },
      {
        ...taskStatusColumn,
        key: AddEditGridColumnKey.TaskStatusId,
        cellClass: ADD_EDIT_DROPDOWN_CLASS,
        editor: TaskStatusDropdownEditor,
        editorOptions: { editOnClick: true },
        formatter: TaskStatusFormatter,
        width: COLUMN_WIDTH_M,
      },
      ...storyPointColumn,
    ];
    const scheduleColumns = [
      {
        ...priorityRushColumn,
        key: AddEditGridColumnKey.ScheduleDirective,
        cellClass: (row: AddEditGridRow) => {
          const isInClosedIteration = checkIfIterationIsClosed({
            iterationId: row[AddEditGridColumnKey.IterationId],
            isIssuePushClosedSprintJiraError,
            iterations,
          });
          return classNames(isInClosedIteration, ADD_EDIT_DROPDOWN_CLASS);
        },
        editor: PriorityRushDropdownEditor,
        editorOptions: { editOnClick: true },
        formatter: PriorityRushFormatter,
        width: COLUMN_WIDTH_M,
      },
      {
        ...doneDateColumn,
        key: AddEditGridColumnKey.DoneDate,
        cellClass: checkDoneDateEditCellClass,
        editor: DateInputEditor,
        editable: (row: AddEditGridRow) => Boolean(row.doneDate),
        editorOptions: { editOnClick: true, commitOnOutsideClick: false },
        formatter: DateFormatter,
        width: COLUMN_WIDTH_M,
      },
      {
        ...targetStartColumn,
        key: AddEditGridColumnKey.TargetStart,
        cellClass: (row: AddEditGridRow) => {
          const isRequiredAndHasNoValue = checkIfFieldIsRequiredAndHasNoValue({
            fieldValue: row.targetStart,
            fieldId: LpSystemId.TargetStart,
            jiraRequiredSystemFieldErrorIds:
              (jiraRequiredErrorIdsByItemId && jiraRequiredErrorIdsByItemId[row.id]?.lpSystemFieldId) ?? [],
          });
          return classNames(isRequiredAndHasNoValue, checkValidDateEditCellClass(row));
        },
        editor: DateInputEditor,
        editorOptions: { editOnClick: true, commitOnOutsideClick: false },
        formatter: DateFormatter,
        width: COLUMN_WIDTH_M,
      },
      {
        ...targetFinishColumn,
        key: AddEditGridColumnKey.TargetFinish,
        cellClass: (row: AddEditGridRow) => {
          const isRequiredAndHasNoValue = checkIfFieldIsRequiredAndHasNoValue({
            fieldValue: row.targetFinish,
            fieldId: LpSystemId.TargetFinish,
            jiraRequiredSystemFieldErrorIds:
              (jiraRequiredErrorIdsByItemId && jiraRequiredErrorIdsByItemId[row.id]?.lpSystemFieldId) ?? [],
          });
          return classNames(isRequiredAndHasNoValue, checkValidDateEditCellClass(row));
        },
        editor: DateInputEditor,
        editorOptions: { editOnClick: true, commitOnOutsideClick: false },
        formatter: DateFormatter,
        width: COLUMN_WIDTH_M,
      },
      {
        ...targetFinishTypeColumn,
        key: AddEditGridColumnKey.TargetFinishType,
        cellClass: ADD_EDIT_DROPDOWN_CLASS,
        editor: TargetFinishTypeDropdownEditor,
        editorOptions: { editOnClick: true },
        formatter: TargetFinishTypeFormatter,
        width: COLUMN_WIDTH_M,
      },
      ...benchmarkEstimateColumn,
      {
        key: AddEditGridColumnKey.WorkLimit,
        name: 'Work Limit (h)',
        cellClass: ({ id, workLimit }: AddEditGridRow) => {
          const isRequiredAndHasNoValue = checkIfFieldIsRequiredAndHasNoValue({
            fieldValue: workLimit,
            fieldId: LpSystemId.WorkLimit,
            jiraRequiredSystemFieldErrorIds:
              (jiraRequiredErrorIdsByItemId && jiraRequiredErrorIdsByItemId[id]?.lpSystemFieldId) ?? [],
          });
          return classNames(isRequiredAndHasNoValue, checkValidWorkLimitCellClass(workLimit ?? 0));
        },
        editor: textEditor,
        formatter: NumberTextFormatter,
        editorOptions: { editOnClick: true },
        resizable: true,
        width: COLUMN_WIDTH_M,
      },
      {
        ...workTypeColumn,
        key: AddEditGridColumnKey.WorkType,
        cellClass: ADD_EDIT_DROPDOWN_CLASS,
        editor: WorkTypeDropdownEditor,
        editorOptions: { editOnClick: true },
        formatter: WorkTypeFormatter,
        width: COLUMN_WIDTH_M,
      },
    ];

    return isTemplateGrid ? baseColumns : [...baseColumns, ...scheduleColumns];
  }, [
    hasStoryPointsFeature,
    isIssuePushClosedSprintJiraError,
    isTemplateGrid,
    iterations,
    jiraRequiredErrorIdsByItemId,
  ]);

  const customColumns = hasDataCustomization
    ? customFieldAddEditColumn(customFields, setMultiEditModalProps, jiraRequiredErrorIdsByItemId)
    : [];

  const allColumns = [...columns, ...customColumns];

  if (isIssuePushClosedSprintJiraError) {
    return reorderColumnsWithScheduleDirectiveOnly({ columns: allColumns });
  }

  if (jiraRequiredErrorIdsByItemId) {
    return reorderColumnsWithSortedErrorsFirst({ columns: allColumns, jiraRequiredErrorIdsByItemId });
  }

  return allColumns;
};

export const useTasksToEditGridRows = (tasks: ReadonlyArray<Item>) => {
  const taskStatusesById = useSelector(getTaskStatusesById);
  const fieldValuesByItemId = useSelector(getEditGridCustomFieldValuesByItemId);
  const hasIterationFeature = useHasFeature(FeatureFlag.iterationBucket);
  const hasStoryPointsFeature = useHasFeature(FeatureFlag.storyPoints);

  return tasks.reduce((acc: Array<AddEditGridRow>, task) => {
    const taskStatus = task.taskStatus ? taskStatusesById[task.taskStatus.id] : undefined;
    const fieldValues = fieldValuesByItemId[task.id] ?? [];
    const workLimitHours = convertSecondsToHours(task.workLimit ?? 0);

    if (taskStatus) {
      acc.push({
        [AddEditGridColumnKey.Id]: task.id,
        [AddEditGridColumnKey.Name]: task.name ?? '',
        [AddEditGridColumnKey.Description]: task.description ?? '',
        [AddEditGridColumnKey.TaskStatusId]: taskStatus.id,
        [AddEditGridColumnKey.ScheduleDirective]: task.scheduleDirective ?? ScheduleDirective.NORMAL,
        [AddEditGridColumnKey.TargetStart]: task.targetStart ?? '',
        [AddEditGridColumnKey.DoneDate]: task.doneDate ?? '',
        [AddEditGridColumnKey.TargetFinish]: task.targetFinish ?? '',
        [AddEditGridColumnKey.TargetFinishType]: task.targetFinishType as TargetFinishType,
        [AddEditGridColumnKey.WorkLimit]: workLimitHours,
        [AddEditGridColumnKey.WorkType]: task.workType ?? WorkType.IN_ORDER,
        [AddEditGridColumnKey.IterationId]: hasIterationFeature ? task.iterationId : undefined,
        [AddEditGridColumnKey.StoryPointsId]: hasStoryPointsFeature ? task.storyPoints?.id : undefined,
        ...getFieldCellValuesByFieldId(fieldValues),
      });
    }

    return acc;
  }, []);
};

export const useIssueTypeUnsetColumnDefinitions = ({
  issueTypeUnsetCustomFieldId,
  setMultiEditModalProps,
  jiraRequiredErrorIdsByItemId,
}: {
  issueTypeUnsetCustomFieldId: number | undefined;
  setMultiEditModalProps: Dispatch<SetStateAction<MultiFieldEditorProps | undefined>>;
  jiraRequiredErrorIdsByItemId?: PushItemsToJiraErrorByItemId;
}) => {
  const issueTypeUnsetCustomField = useSelector((state) => getCustomFieldById(state, issueTypeUnsetCustomFieldId ?? 0));
  const organization = useSelector(getCurrentOrganization);
  const hasDataCustomization = !!organization?.flags.hasDataCustomization;

  const issueTypeUnsetColumn =
    hasDataCustomization && issueTypeUnsetCustomField
      ? customFieldAddEditColumn([issueTypeUnsetCustomField], setMultiEditModalProps, jiraRequiredErrorIdsByItemId)
      : [];

  return [
    {
      ...itemNameColumn(ItemType.TASKS),
      key: AddEditGridColumnKey.Name,
      cellClass: checkValidNameEditCellClass,
      editor: textEditor,
      frozen: true,
      width: customColumnDefinitions[StandardColumns.Assignment].widths.grid,
    },
    ...issueTypeUnsetColumn,
  ];
};
