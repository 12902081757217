import { capitalize } from 'lodash';
import { useEffect, useState } from 'react';

import { JiraProjectModalTableDeleteCell } from 'features/jira_project/modal/common/jira_project_modal_table_delete_cell';
import { JiraProjectModalMultiPicklistCell } from 'features/jira_project/modal/common/jira_project_modal_table_multi_picklist_cell';
import { JiraProjectModalTableNameCell } from 'features/jira_project/modal/common/jira_project_modal_table_name_cell';
import { JiraProjectModalTableRow } from 'features/jira_project/modal/common/jira_project_modal_table_row';
import { JiraAutocompleteDropdown } from 'features/jira_project/modal/sections/project_and_issues/jira_autocomplete_dropdown';
import { AdvancedFilters, JiraAdvancedFilter } from 'features/jira_project/modal/types';

import { useAdvancedFilters } from './use_advanced_filters';
import { getParentName, getKey, isSingleSelect, isSprint, formatNameColumn } from './utils';

interface AdvancedFilterRowProps {
  filter: JiraAdvancedFilter;
  advancedFilters: AdvancedFilters;
  setAdvancedFilters: (filters: AdvancedFilters) => void;
}

export const AdvancedFilterRow = ({ filter, advancedFilters, setAdvancedFilters }: AdvancedFilterRowProps) => {
  const { displayName, types, value } = filter;
  const { selectedValues, setFieldValue } = useAdvancedFilters({
    parentId: getParentName(filter),
    advancedFilters,
    setAdvancedFilters,
  });
  const canDeleteRowValues = selectedValues.length > 0;

  const [dropdowns, setDropdowns] = useState<Array<number>>([0]);

  const isSprintFilter = isSprint(types);
  const isSingleSelectFilter = isSingleSelect(types);
  const nameColumn = isSprintFilter || isSingleSelectFilter ? formatNameColumn(value) : displayName;

  const deleteRowValues = () => {
    setFieldValue({});
  };

  useEffect(() => {
    const newDropdowns =
      selectedValues.length === 0 ? [0] : Array.from({ length: selectedValues.length + 1 }, (_, i) => i);
    if (JSON.stringify(newDropdowns) !== JSON.stringify(dropdowns)) {
      setDropdowns(newDropdowns);
    }
  }, [selectedValues, dropdowns]);

  return (
    <JiraProjectModalTableRow key={displayName}>
      <JiraProjectModalTableNameCell content={capitalize(nameColumn)} />
      <JiraProjectModalMultiPicklistCell>
        {dropdowns.map((index) => (
          <JiraAutocompleteDropdown
            parent={getParentName(filter) ?? undefined}
            index={index}
            key={getKey(
              index,
              selectedValues.map((selectedFilter) => selectedFilter.value ?? selectedFilter.displayName)
            )}
            placeholder={`Search for ${nameColumn}...`}
            selectedValues={selectedValues}
            setFieldValue={setFieldValue}
          />
        ))}
      </JiraProjectModalMultiPicklistCell>
      <JiraProjectModalTableDeleteCell enabled={canDeleteRowValues} onClick={deleteRowValues} />
    </JiraProjectModalTableRow>
  );
};
