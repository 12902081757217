import { createSelector } from 'reselect';

import { StoryPoints } from 'daos/model_types';
import { groupRecordBy, readonlyArray } from 'lib/readonly_record';
import { getAncestorProjectForItemId, getItemForId } from 'redux/entities/selectors/item';
import { getStoryPointSchemeOwnersById } from 'redux/entities/selectors/story_point_scheme_owners';
import { getStoryPointSchemesById } from 'redux/entities/selectors/story_point_schemes';
import { RootState } from 'redux/root_reducer';

export const storyPointsNumericValueComparator = (a: StoryPoints, b: StoryPoints): number => {
  return a.value - b.value;
};

const getStoryPointsById = (state: RootState) => state.entities.storyPoints;

export const getStoryPointForId = (state: RootState, id: number) => getStoryPointsById(state)[id];

const getActiveStoryPointsForProjectId = createSelector(
  getStoryPointSchemeOwnersById,
  getStoryPointSchemesById,
  getStoryPointsById,
  (_: RootState, projectId: number) => projectId,
  (schemeOwnersById, schemesById, storyPointsById, projectId) => {
    const schemeOwners = Object.values(schemeOwnersById);
    const projectSchemeOwnerOrDefault =
      schemeOwners.find((schemeOwner) => schemeOwner.item?.id === projectId) ??
      schemeOwners.find((schemeOwner) => schemeOwner.item === null);

    const scheme = schemesById[projectSchemeOwnerOrDefault?.storyPointScheme.id ?? 0];

    const schemeStoryPoints = Object.values(storyPointsById).filter(
      (storyPoint) => storyPoint.storyPointScheme.id === scheme?.id && storyPoint.archivedAt === null
    );

    return readonlyArray(schemeStoryPoints);
  }
);

const getActiveStoryPointsForTaskId = createSelector(
  getAncestorProjectForItemId,
  (state: RootState) => state,
  (project, state) => {
    if (!project) {
      return [];
    }

    return getActiveStoryPointsForProjectId(state, project.id);
  }
);

const getActiveStoryPointsForTaskIdSortedByValue = createSelector(getActiveStoryPointsForTaskId, (storyPoints) => {
  return readonlyArray([...storyPoints].sort(storyPointsNumericValueComparator));
});

export const getStoryPointSchemeIdForTaskId = createSelector(
  getActiveStoryPointsForTaskId,
  (storyPoints) => storyPoints[0]?.storyPointScheme.id ?? 0
);

const getAllStoryPoints = (availableStoryPoints: ReadonlyArray<StoryPoints>, inUseStoryPoint?: StoryPoints) => {
  if (!inUseStoryPoint) {
    return availableStoryPoints;
  }

  const uniqueStoryPointSet = new Set(availableStoryPoints).add(inUseStoryPoint);
  return Array.from(uniqueStoryPointSet);
};

export const getActiveAndInUseStoryPointsForTaskId = createSelector(
  (state: RootState) => state,
  (_: RootState, taskId: number) => taskId,
  (state, taskId) => {
    const taskStoryPointId = getItemForId(state, taskId)?.storyPoints?.id ?? 0;
    const taskStoryPoint = getStoryPointForId(state, taskStoryPointId);
    const availableStoryPointsForTask = getActiveStoryPointsForTaskIdSortedByValue(state, taskId);

    return getAllStoryPoints(availableStoryPointsForTask, taskStoryPoint);
  }
);

const getStoryPointsBySchemeId = createSelector(getStoryPointsById, (storyPointsById) => {
  return groupRecordBy(storyPointsById, (storyPoint) => storyPoint.storyPointScheme.id);
});

export const getStoryPointsForSchemeId = createSelector(
  getStoryPointsBySchemeId,
  (_: RootState, schemeId: number) => schemeId,
  (storyPointsBySchemeId, schemeId) => {
    return storyPointsBySchemeId[schemeId] ?? readonlyArray([]);
  }
);

export const getStoryPointsForSchemeIdSortedByValue = createSelector(getStoryPointsForSchemeId, (storyPoints) => {
  return readonlyArray([...storyPoints].sort(storyPointsNumericValueComparator));
});

export const getActiveStoryPointsForSchemeId = createSelector(
  getStoryPointsBySchemeId,
  (_: RootState, schemeId: number) => schemeId,
  (storyPointsBySchemeId, schemeId) => {
    const activeStoryPoints = storyPointsBySchemeId[schemeId]?.filter((point) => point.archivedAt === null) ?? [];
    return readonlyArray(activeStoryPoints);
  }
);
