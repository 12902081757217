import { archiveSolid, boxAltSolid, boxHeartSolid, boxOpenSolid, layerGroupSolid } from 'features/common/lp_icon';
import { request } from 'lib/api';
import { HttpMethod } from 'lib/api/types';
import { ReadonlyRecord } from 'lib/readonly_record';

import { PackageStatus } from './enums';
import { Item } from './model_types';
import { defineModel, reduceIncludedOptions } from './shared';
import { backend } from './urls';

const { createBody, deleteBody, resource } = defineModel({
  apiType: 'items',
  itemType: 'packages',
  type: 'PKG',
});

const { PKG, resourceId } = resource;

export enum PackageStatusDisplay {
  Archived = 'ARCHIVED',
  Backlog = 'PENDING',
  Scheduled = 'SCHEDULED',
  Template = 'TEMPLATES',
  Capacity = 'CAPACITY',
}

export const packageStatusDisplayNameByPackageStatus: ReadonlyRecord<PackageStatus, PackageStatusDisplay> = {
  [PackageStatus.SCHEDULED]: PackageStatusDisplay.Scheduled,
  [PackageStatus.BACKLOG]: PackageStatusDisplay.Backlog,
  [PackageStatus.ARCHIVED]: PackageStatusDisplay.Archived,
  [PackageStatus.TEMPLATE]: PackageStatusDisplay.Template,
  [PackageStatus.CAPACITY]: PackageStatusDisplay.Capacity,
};

export const packageStatusIconByPackageStatus = {
  [PackageStatus.SCHEDULED]: boxAltSolid,
  [PackageStatus.BACKLOG]: layerGroupSolid,
  [PackageStatus.ARCHIVED]: archiveSolid,
  [PackageStatus.TEMPLATE]: boxHeartSolid,
  [PackageStatus.CAPACITY]: boxOpenSolid,
};

interface Includes {
  includeChildItemMetrics?: boolean;
  includeChildren?: boolean;
  includeChildrenFieldValues?: boolean;
  includeChildrenPredecessorDependencies?: boolean;
  includeChildrenSuccessorDependencies?: boolean;
  includeFieldValues?: boolean;
  includeItemMetrics?: boolean;
  includeParent?: boolean;
  includePredecessorDependencies?: boolean;
  includeSchedulingLimits?: boolean;
  includeSuccessorDependencies?: boolean;
}

const includes = ({
  includeChildItemMetrics,
  includeChildren,
  includeChildrenFieldValues,
  includeChildrenPredecessorDependencies,
  includeChildrenSuccessorDependencies,
  includeItemMetrics,
  includeParent,
  includePredecessorDependencies,
  includeSuccessorDependencies,
  includeSchedulingLimits,
}: Includes = {}) =>
  reduceIncludedOptions([
    includeChildItemMetrics && 'children.itemMetrics',
    includeChildren && 'children',
    includeChildrenFieldValues && 'children.fieldValues.itemValue',
    includeItemMetrics && 'itemMetrics',
    includeParent && 'parent',
    includeSuccessorDependencies && 'successorDependencies',
    includePredecessorDependencies && 'predecessorDependencies',
    includeChildrenPredecessorDependencies && 'children.predecessorDependencies',
    includeChildrenSuccessorDependencies && 'children.predecessorDependencies',
    includeSchedulingLimits && 'schedulingLimits',
    'effectiveFieldValues',
    'effectiveFieldValues.fields',
    'effectiveFieldValues.fieldValues.itemValue',
  ]);

interface PackagesParams {
  organizationId: number;
  workspaceId: number;
}

interface SinglePackageParams extends PackagesParams {
  itemId: number;
}

interface PackageOptions {
  filter?: string;
  include?: Includes;
  skipReduce?: boolean;
}

const packageCreate = (params: PackagesParams, pkg: Partial<Item>) =>
  request(backend.items.url(params), PKG, { ...createBody(pkg) });

const packageDestroy = (params: SinglePackageParams, pkgId: number) =>
  request(backend.item.url(params), PKG, deleteBody(pkgId));

const packageFetch = (params: SinglePackageParams, { skipReduce, include }: PackageOptions = {}) =>
  request(backend.item.url(params, { include: includes(include) }), PKG, {
    meta: { skipReduce },
    method: HttpMethod.GET,
  });

const packageFetchAll = (params: PackagesParams, { filter, include, skipReduce }: PackageOptions) =>
  request(backend.items.url(params, { filter, include: includes(include) }), PKG, {
    meta: { skipReduce },
    method: HttpMethod.GET,
  });

export const PackageDao = {
  create: packageCreate,
  destroy: packageDestroy,
  fetch: packageFetch,
  fetchAll: packageFetchAll,
  id: resourceId,
} as const;
