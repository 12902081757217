import { ExtractRouteParams, generatePath } from 'react-router';

import { OriginViewValueType } from 'lib/avo/client';
import { addGenericIdsToUrlPattern, namedUrlsMatcher } from 'lib/url_helpers';

export function urlSearchParams(args?: Record<string, string | number | boolean | undefined>): string {
  if (!args) {
    return '';
  }

  const urlSearchParams = new URLSearchParams();
  let hasSearchParams = false;

  for (const key in args) {
    if (Object.prototype.hasOwnProperty.call(args, key)) {
      const value = args[key];
      if (value !== undefined) {
        urlSearchParams.append(key, String(value));
        hasSearchParams = true;
      }
    }
  }

  if (hasSearchParams) {
    return urlSearchParams.toString();
  } else {
    return '';
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface NoOptions {}

interface UrlOptions<QueryType = NoOptions, HashType = NoOptions> {
  readonly filter?: string;
  readonly includeItemsFilter?: string;
  readonly include?: ReadonlyArray<string>;
  readonly order?: ReadonlyArray<string>;
  readonly query?: QueryType;
  readonly hash?: HashType;
  readonly limit?: number;
}

export class UrlBuilder<S extends string, QueryType = NoOptions, HashType = NoOptions> {
  readonly pattern: S;
  constructor(pattern: S) {
    this.pattern = pattern;
  }

  public url(params: ExtractRouteParams<S>, options?: UrlOptions<QueryType, HashType>): string {
    let query = { ...options?.query };
    if (options?.filter) {
      query = { ...query, filter: options.filter };
    }
    if (options?.includeItemsFilter) {
      query = { ...query, includeItemsFilter: options.includeItemsFilter };
    }
    if (options?.include?.length) {
      query = { ...query, include: options.include.join(',') };
    }
    if (options?.limit) {
      query = { ...query, limit: options.limit };
    }
    if (options?.order?.length) {
      query = { ...query, order: options.order.join(',') };
    }

    //todo remove the cast when we are fully strict
    const queryString = urlSearchParams(query as unknown as Record<string, string | number | boolean>);
    const hashString = urlSearchParams({ ...options?.hash } as unknown as Record<string, string | number | boolean>);

    let url = generatePath(this.pattern, params);

    if (queryString) {
      url = `${url}?${queryString}`;
    }
    if (hashString) {
      url = `${url}#${hashString}`;
    }

    return url;
  }
}

function registerUrlWithSegmentNameMatcher(pattern: string, pageName: string, viewName: OriginViewValueType | null) {
  const sanitizedPattern = addGenericIdsToUrlPattern(pattern);
  namedUrlsMatcher[sanitizedPattern] = { pageName, viewName };
}

export function url<S extends string>(
  pattern: S,
  pageName = '',
  viewName: OriginViewValueType | null = null
): UrlBuilder<S> {
  registerUrlWithSegmentNameMatcher(pattern, pageName, viewName);

  return new UrlBuilder<S>(pattern);
}
