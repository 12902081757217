import { Iteration, ResourceId, UserWorkspaceSettings } from 'daos/model_types';
import { request } from 'lib/api';
import { HttpMethod } from 'lib/api/types';

import {
  ItemType,
  TimesheetGroupingType,
  UserWorkspaceSettingFieldName,
  WorkloadDayRange,
  WorkloadGroupingType,
} from './enums';
import { defineModel } from './shared';
import { UserCustomColumn } from './types';
import { backend } from './urls';

const { resource } = defineModel({
  apiType: 'userWorkspaceSettings',
  type: 'USER_WORKSPACE_SETTING',
});

const { USER_WORKSPACE_SETTING, resourceId } = resource;

interface UserWorkspaceSettingsParams {
  organizationId: number;
  workspaceId: number;
}
interface UserWorkspaceSettingsSingleParams extends UserWorkspaceSettingsParams {
  settingFieldName: UserWorkspaceSettingFieldName;
}

const update = (
  params: UserWorkspaceSettingsSingleParams,
  settings:
    | ReadonlyArray<string>
    | boolean
    | WorkloadDayRange
    | TimesheetGroupingType
    | WorkloadGroupingType
    | UserCustomColumn
    | ItemType
    | ReadonlyArray<ResourceId<Iteration>>
) =>
  request(backend.userWorkspaceSettings.url(params), USER_WORKSPACE_SETTING, {
    body: JSON.stringify({ data: { [params.settingFieldName]: settings } }),
    method: HttpMethod.PATCH,
  });

const updateAll = (params: UserWorkspaceSettingsParams, settings: Partial<UserWorkspaceSettings>) =>
  request(backend.userWorkspaceSettings.url(params), USER_WORKSPACE_SETTING, {
    body: JSON.stringify({ data: settings }),
    method: HttpMethod.PATCH,
  });

export const UserWorkspaceSettingsDao = {
  id: resourceId,
  update,
  updateAll,
} as const;
