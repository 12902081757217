import { useCallback } from 'react';
import { SelectColumn } from 'react-data-grid';
import { useDispatch, useSelector } from 'react-redux';

import { ItemType, StatusFilterGroups } from 'daos/enums';
import { getIsBulkSelectionActive } from 'features/common/bulk_selection/selectors';
import { clearBulkData, setBulkSelectedItemIds } from 'features/common/bulk_selection/slice';
import { selectAllColumn } from 'features/common/data_grid/column_definitions/select_all_column_definition';
import { SelectColumnFormatter } from 'features/common/data_grid/formatters/select_column';
import { getItemRowSchedulingTypeMatchesStatusGroup } from 'features/common/data_grid/safe_widgets/helpers';
import { getDataGridItems } from 'features/common/data_grid/selectors';
import { ItemSummaryFormatterProps } from 'features/common/data_grid/types';
import {
  SelectionDropdownLocation,
  SelectionDropdownOptions,
} from 'features/common/inputs/dropdowns/selection_dropdown/enums';
import StatusFilter from 'features/common/inputs/status_filter_menu';
import { ItemRow } from 'features/dashboards_v2/widget/widgets/reports/types';
import { useTaskStatusFilterCallback } from 'hooks/use_task_status_filter';

const SelectTaskHeaderRenderer = () => {
  const dispatch = useDispatch();

  const prioritizedTasks = useSelector(getDataGridItems);
  const isBulkSelectionActive = useSelector(getIsBulkSelectionActive);

  const getTasksForFilter = useTaskStatusFilterCallback(prioritizedTasks.map((item) => item.id));
  const handleSelectByTaskStatus = useCallback(
    (statusType: StatusFilterGroups) => {
      if (statusType === StatusFilterGroups.None) {
        if (isBulkSelectionActive) {
          dispatch(clearBulkData());
          return;
        }
      }

      const filteredTaskIds = getTasksForFilter(statusType);
      dispatch(setBulkSelectedItemIds([...filteredTaskIds]));
      return;
    },
    [isBulkSelectionActive, dispatch, getTasksForFilter]
  );
  return (
    <StatusFilter
      className="status-filter-grid"
      selectedTaskStatus={StatusFilterGroups.All}
      handleSelectByStatus={handleSelectByTaskStatus}
      tabIndex={-1}
    />
  );
};

const SelectTaskWidgetListHeader = ({ rowData }: { rowData: ReadonlyArray<ItemRow> }) => {
  const dispatch = useDispatch();

  const handleSelectByTaskStatus = (statusType: StatusFilterGroups) => {
    if (statusType === StatusFilterGroups.None) {
      dispatch(clearBulkData());
      return;
    }

    const filteredRows = rowData.filter((row) =>
      getItemRowSchedulingTypeMatchesStatusGroup(row.itemStatus.taskSchedulingType, statusType)
    );
    dispatch(setBulkSelectedItemIds(filteredRows.map((row) => row.itemId)));
    return;
  };

  return (
    <StatusFilter
      className="status-filter-grid"
      selectedTaskStatus={StatusFilterGroups.All}
      handleSelectByStatus={handleSelectByTaskStatus}
      tabIndex={-1}
    />
  );
};

export const selectTaskColumn = {
  ...SelectColumn,
  headerCellClass: 'lp-data-grid__select-header',
  cellClass: 'lp-data-grid__select',
  headerRenderer: () => <SelectTaskHeaderRenderer />,
  formatter: SelectColumnFormatter,
  summaryFormatter: (_props: ItemSummaryFormatterProps) => {
    return null;
  },
};

export const selectAllPortfolioProjectsColumn = (onSelectAll: (selectionOption: SelectionDropdownOptions) => void) =>
  selectAllColumn(onSelectAll, SelectionDropdownLocation.SinglePackage);

export const selectAssignmentsColumn = (onSelectAll: (selectionOption: SelectionDropdownOptions) => void) =>
  selectAllColumn(onSelectAll, SelectionDropdownLocation.AssignmentGrid);

export const itemListWidgetSelectColumn = (
  onSelectAll: (selectionOption: SelectionDropdownOptions) => void,
  itemType: ItemType,
  rowData: ReadonlyArray<ItemRow>
) => {
  switch (itemType) {
    case ItemType.ASSIGNMENTS:
      return selectAssignmentsColumn(onSelectAll);

    case ItemType.TASKS:
      return {
        ...selectTaskColumn,
        headerRenderer: () => <SelectTaskWidgetListHeader rowData={rowData} />,
        summaryFormatter: null,
      };

    case ItemType.FOLDERS:
    case ItemType.PROJECTS:
    case ItemType.PACKAGES:
      return selectAllPortfolioProjectsColumn(onSelectAll);

    default:
      return SelectColumn;
  }
};
