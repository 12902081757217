import { JiraAutoCompleteResult } from 'features/jira_project/modal/types';

export const updateParentIssueIdsOrKeys = (
  parentIssueIdsOrKeys: Array<string>,
  newValue?: JiraAutoCompleteResult,
  index?: number
): Array<string> => {
  const newParentIssueIdsOrKeys = [...parentIssueIdsOrKeys];
  if (index === undefined) {
    return [];
  }
  if (newValue) {
    newParentIssueIdsOrKeys[index] = newValue.value;
  } else {
    newParentIssueIdsOrKeys.splice(index, 1);
  }

  return newParentIssueIdsOrKeys.filter((value) => value !== undefined);
};
