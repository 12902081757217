import classNames from 'classnames';

import { ScheduleBarRendererProps } from 'features/common/schedule_bar/types';
import { ScheduleViewType } from 'hooks/use_items_date_range';

import { AssignmentBar } from './assignment_bar';
import { Bar } from './bar';
import { DoneBar } from './done_bar';
import { TargetDateLabels, ExpectedDateLabels } from './labels';

export const ScheduleBarRenderer = ({
  doneDate,
  effectiveTargetFinish,
  effectiveTargetStart,
  expectedFinish,
  expectedStart,
  formatDateForDisplay,
  highEffort,
  isAncestorItemScheduleBar,
  isAssignment,
  isAssignmentNeedsAttention,
  isAssignmentPlaceholder,
  isAssignmentTrackingOnly,
  isClipped,
  isDebugMode,
  isDone,
  isDoneAfterEffectiveTargetFinish,
  isDoneLate,
  isEffectivelyOnHold,
  isExpectedStartAfterEffectiveTargetFinish,
  isExpectedStartBeforeEffectiveTargetStart,
  isExpectedStartEqualExpectedFinish,
  isIncomplete,
  isLate,
  isLateRisk,
  isOnHold,
  isParentEffectivelyOnHold,
  isParentOnHold,
  isScheduled,
  isTask,
  itemId,
  latestFinish,
  rollupEarliestActiveTargetFinish,
  rollupLatestTargetFinish,
  scheduleBarDateOffsetPercents,
  scheduleBarLabelStyles,
  scheduleBarStyles,
  showDateLabels,
  showPredecessor,
  showRollupDates,
  showRollupEarliestActiveTargetFinish,
  showRollupLatestTargetFinish,
  showSuccessor,
  targetFinish,
  targetStart,
  viewType,
  assignedToDoneIteration,
}: ScheduleBarRendererProps) => {
  const showEffectiveStartShadow =
    (isOnHold || isEffectivelyOnHold) && !!targetStart && !targetFinish && !effectiveTargetFinish;

  return (
    <>
      {showDateLabels && (
        <TargetDateLabels
          effectiveTargetFinish={effectiveTargetFinish}
          effectiveTargetStart={effectiveTargetStart}
          formatDateForDisplay={formatDateForDisplay}
          isDoneAfterEffectiveTargetFinish={isDoneAfterEffectiveTargetFinish}
          isExpectedStartBeforeEffectiveTargetStart={isExpectedStartBeforeEffectiveTargetStart}
          isLate={isLate}
          isLateRisk={isLateRisk}
          isTask={isTask}
          rollupEarliestActiveTargetFinish={rollupEarliestActiveTargetFinish}
          rollupLatestTargetFinish={rollupLatestTargetFinish}
          scheduleBarDateOffsetPercents={scheduleBarDateOffsetPercents}
          scheduleBarLabelStyles={scheduleBarLabelStyles}
          showRollupEarliestActiveTargetFinish={showRollupEarliestActiveTargetFinish}
          showRollupLatestTargetFinish={showRollupLatestTargetFinish}
          targetFinish={targetFinish}
          targetStart={targetStart}
        />
      )}
      <div
        className={classNames('lp-schedule__bar', {
          'lp-schedule__bar--debug': isDebugMode,
        })}
      >
        {isDone ? (
          <DoneBar
            doneDate={doneDate}
            hasHighEffort={!!highEffort}
            isAncestorItemScheduleBar={isAncestorItemScheduleBar}
            isAssignment={isAssignment}
            isAssignmentTable={viewType === ScheduleViewType.AssignmentTable}
            isDoneLate={isDoneLate}
            itemId={itemId}
            showSuccessor={showSuccessor}
            style={scheduleBarStyles.doneIcon}
          />
        ) : (
          <>
            {isAssignment && (
              <AssignmentBar
                isAssignmentPlaceholder={isAssignmentPlaceholder}
                isAssignmentTrackingOnly={isAssignmentTrackingOnly}
                isDone={isDone}
                isAssignmentNeedsAttention={isAssignmentNeedsAttention}
                isParentEffectivelyOnHold={isParentEffectivelyOnHold}
                isParentOnHold={isParentOnHold}
                isScheduled={isScheduled}
                assignedToDoneIteration={!!assignedToDoneIteration}
              />
            )}
            {isScheduled && !isEffectivelyOnHold && (
              <Bar
                effectiveTargetFinish={effectiveTargetFinish}
                isAncestorItemScheduleBar={isAncestorItemScheduleBar}
                isClipped={isClipped}
                isDoneLate={isDoneLate}
                isEffectivelyOnHold={isEffectivelyOnHold}
                isExpectedStartAfterEffectiveTargetFinish={isExpectedStartAfterEffectiveTargetFinish}
                isIncomplete={isIncomplete}
                isLate={isLate}
                isLateRisk={isLateRisk}
                isScheduled={isScheduled}
                scheduleBarStyles={scheduleBarStyles}
                showEffectiveStartShadow={showEffectiveStartShadow}
                showOnHoldIcon={viewType !== ScheduleViewType.AssignmentTable && (isOnHold || isEffectivelyOnHold)}
                showPredecessor={showPredecessor}
                showRollupDates={showRollupDates}
                showSuccessor={showSuccessor}
                targetFinish={targetFinish}
              />
            )}
          </>
        )}
      </div>
      {showDateLabels && (
        <ExpectedDateLabels
          doneDate={doneDate}
          expectedFinish={expectedFinish}
          expectedStart={expectedStart}
          formatDateForDisplay={formatDateForDisplay}
          isExpectedStartBeforeEffectiveTargetStart={isExpectedStartBeforeEffectiveTargetStart}
          isExpectedStartEqualExpectedFinish={isExpectedStartEqualExpectedFinish}
          isLate={isLate}
          isLateRisk={isLateRisk}
          latestFinish={latestFinish}
          scheduleBarDateOffsetPercents={scheduleBarDateOffsetPercents}
          scheduleBarLabelStyles={scheduleBarLabelStyles}
        />
      )}
    </>
  );
};
